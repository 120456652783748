import axios from './axios';
import { PlanTypesSlugMap } from '../utilities/config';

const baseUrl = '/rates-travel';

export function getRatesTravelSingle(planId) {
  return axios({
    method: 'get',
    url: `${baseUrl}/${planId}/single`
  });
}

export function getRatesTravelMulti(planId) {
  return axios({
    method: 'get',
    url: `${baseUrl}/${planId}/multi`
  });
}

export function importRatesTravelSingle({ csv, planId }) {
  let data = new FormData();
  data.append('csv', csv);
  data.append('planId', planId);

  return axios({
    method: 'post',
    url: `${baseUrl}/single/import`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  });
}

export function importRatesTravelMulti({ csv, planId }) {
  let data = new FormData();
  data.append('csv', csv);
  data.append('planId', planId);

  return axios({
    method: 'post',
    url: `${baseUrl}/multi/import`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  });
}

export function deleteAllRates({ planId, rateType }) {
  return axios({
    method: 'delete',
    url: `${baseUrl}/${planId}/${rateType}`
  });
}
