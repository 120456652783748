import React from 'react';
import { Typography, Button } from '@mui/material';
import { Affiliates } from './components';
import { PhoneFormat } from 'components';
import { Link as RouterLink } from 'react-router-dom';
import { Alert } from '@mui/material';
import { usePlans } from 'hooks';
import Spinner from 'components/Spinner';

const Details = ({ client }) => {
  const {
    status: planStatus,
    error: planError,
    plans,
    result: planResult
  } = usePlans();

  return (
    <div className="p-3">
      <div className="mb-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div>
            <div className="mb-6">
              <Typography variant="h4">Account Details</Typography>
            </div>
            <div className="mb-3">
              <Typography variant="body2">EMAIL</Typography>
              <Typography variant="body1" gutterBottom>
                {client.email}
              </Typography>
            </div>

            <div className="mb-3">
              <Typography variant="body2">CELL#</Typography>
              <Typography variant="body1" gutterBottom>
                <PhoneFormat number={client.cellNo} />
              </Typography>
            </div>

            <div className="mb-3">
              <Typography variant="body2">WEBSITE</Typography>
              <Typography variant="body1" gutterBottom>
                {client.domain}
              </Typography>
            </div>

            {client.quoteMsg && (
              <>
                <Typography variant="body2">QUOTE MESSAGE TEMPLATE</Typography>
                <div className="mt-1 p-3 bg-gray-50">
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {client.quoteMsg}
                  </Typography>
                </div>
              </>
            )}
          </div>
          {/* col1 */}

          <div>
            {client.twilio && (
              <>
                <div className="mb-6">
                  <Typography variant="h4">Twilio</Typography>
                </div>
                <div className="mb-3">
                  <Typography variant="body2">ACCOUNT ID</Typography>
                  <Typography variant="body1" gutterBottom>
                    {client.twilio.accountId}
                  </Typography>
                </div>

                <div className="mb-3">
                  <Typography variant="body2">TOKEN</Typography>
                  <Typography variant="body1" gutterBottom>
                    {client.twilio.token}
                  </Typography>
                </div>

                <div className="mb-3">
                  <Typography variant="body2">PHONE#</Typography>
                  <Typography variant="body1" gutterBottom>
                    <PhoneFormat number={client.twilio.phone} />
                  </Typography>
                </div>
              </>
            )}
          </div>
          {/* col2 */}

          <div>
            <div className="mb-6">
              <Typography variant="h4">Options</Typography>
            </div>
            <div className="mb-3">
              <Typography variant="body2">DEFAULT DEDUCTIBLE</Typography>
              <Typography variant="body1" gutterBottom>
                {client.options?.deductible || '0'}
              </Typography>
            </div>

            <div className="mb-3">
              <Typography variant="body2">DEF. COVERAGE SUPER</Typography>
              <Typography variant="body1" gutterBottom>
                {client.options?.coverageSuper}
              </Typography>
            </div>

            <div className="mb-3">
              <Typography variant="body2">DEF. COVERAGE VISITOR</Typography>
              <Typography variant="body1" gutterBottom>
                {client.options?.coverageVisitor}
              </Typography>
            </div>

            <div className="mb-3">
              <Typography variant="body2">OPT. PLANS</Typography>
              {planStatus === 'loading' && <Spinner />}
              {planError && (
                <div className="my-3">
                  <Alert severity="warning">{planError}</Alert>
                </div>
              )}
              {planResult && planResult.length && client.options?.plans ? (
                <ul>
                  {client.options.plans.map((key) => (
                    <li className="py-1 border-b border-gray-50">
                      {plans[key]?.name}
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography variant="body1" gutterBottom>
                  All
                </Typography>
              )}
            </div>
          </div>
          {/* col3 */}
        </div>

        <div className="mt-6">
          <div className="mb-6">
            <Typography variant="h4">Affiliates</Typography>
          </div>
          <Affiliates affiliates={client.affiliates} plans={plans} />
          <div className="py-3">
            <Button
              color="primary"
              component={RouterLink}
              to="import-affiliates"
              variant="contained"
            >
              Import Affiliates
            </Button>{' '}
          </div>
        </div>
        {/* col4 */}
      </div>
    </div>
  );
};

export default Details;
