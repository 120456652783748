import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

const Header = ({ plan }) => {
  return (
    <div className="p-3">
      <div className="flex sm:justify-between sm:items-center">
        <div>
          <Typography component="h1" variant="h2">
            Plan Summary
          </Typography>
          <Typography variant="h5">{plan.company}</Typography>
        </div>

        <Button
          color="primary"
          component={RouterLink}
          to="update"
          variant="contained"
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default Header;
