import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import { getShortUrls } from '../../api/shortUrlsApi';

const shortUrl = new schema.Entity('shortUrls', {}, { idAttribute: '_id' });
const shortUrlsSchema = [shortUrl];

export const fetchShortUrls = createAsyncThunk(
  'shortUrls/fetchShortUrls',
  async (params) => await getShortUrls(params)
);

export const shortUrlSlice = createSlice({
  name: 'shortUrl',
  initialState: {
    data: { entities: { shortUrls: {} }, result: [] },
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchShortUrls.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchShortUrls.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        const normalizedShortUrls = normalize(action.payload, shortUrlsSchema);
        state.data = normalizedShortUrls;
        state.data.result = normalizedShortUrls.result || [];
        state.data.entities.shortUrls =
          normalizedShortUrls.entities.shortUrls || {};
      })
      .addCase(fetchShortUrls.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default shortUrlSlice.reducer;
