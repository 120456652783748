import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Header, Details } from './components';
import { useSelector } from 'react-redux';

const ClientView = ({ deleteClient, plans }) => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const result = useSelector((state) => state.clients.data.result);
  const clients = useSelector((state) => state.clients.data.entities.clients);

  useEffect(() => {
    if (result.length === 0) {
      navigate('/dashboard/clients');
    }
  }, [result.length]);

  return (
    <>
      {clients?.[clientId] ? (
        <>
          <Header client={clients?.[clientId]} />
          <Details client={clients?.[clientId]} />
        </>
      ) : (
        <p>Invalid Client</p>
      )}
    </>
  );
};

export default ClientView;
