/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import Group from '@mui/icons-material/Group';
import Chat from '@mui/icons-material/Chat';
import BusinessCenter from '@mui/icons-material/BusinessCenter';
import Receipt from '@mui/icons-material/LibraryBooks';
import ListAlt from '@mui/icons-material/ListAlt';
import LinkIcon from '@mui/icons-material/Link';

const config = [
  {
    title: 'Client',
    href: '/dashboard/clients',
    icon: Group
  },
  {
    title: 'Quote Requests',
    href: '/dashboard/quote-requests/0',
    icon: Chat
  },
  {
    title: 'VTC Plans',
    href: '/dashboard/plans-visitor',
    icon: BusinessCenter
  },
  {
    title: 'VTC Plan Details',
    href: '/dashboard/plan-details/visitor',
    icon: ListAlt
  },
  {
    title: 'Int. Stu. Plan Details',
    href: '/dashboard/plan-details/student',
    icon: ListAlt
  },
  {
    title: 'Travel Plans',
    href: '/dashboard/plans-travel',
    icon: BusinessCenter
  },
  {
    title: 'Plans Summary',
    href: '/dashboard/plan-summary',
    icon: Receipt
  },
  {
    title: 'Short URLs',
    href: '/dashboard/short-urls',
    icon: LinkIcon
  }
];

export default config;
