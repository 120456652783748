import axios from './axios';

const baseUrl = '/plans';

export function getPlans() {
  return axios({
    method: 'get',
    url: baseUrl
  });
}

export function savePlan({ planId, ...rest }) {
  return axios({
    method: planId ? 'put' : 'post',
    url: planId ? `${baseUrl}/${planId}` : baseUrl,
    data: { ...rest, _id: planId }
  });
}

export function planStatus({ planId, status }) {
  return axios({
    method: 'get',
    url: `${baseUrl}/${planId}/status?isActive=${status}`
  });
}
