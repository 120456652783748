import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';

const Header = ({ plan, field }) => {
  return (
    <>
      <Typography component="h1" variant="h2">
        {plan.company}
      </Typography>
      <div className="py-3">
        <Typography variant="h5">
          Update <strong>{field}</strong> details
        </Typography>
      </div>
    </>
  );
};

export default Header;
