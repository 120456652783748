import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, TextField, Typography } from '@mui/material';
import * as clientApi from '../../api/clientApi';
import { toast } from 'react-toastify';
import { Log } from './components';
import phoneFormatter from 'phone-formatter';
import { toUpperCaseEachWord } from 'utilities';

const Text = () => {
  const [msgSegments, setMsgSegments] = useState(1);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [signature, setSignature] = useState(null);
  const clientStatus = useSelector((state) => state.clients.status);
  const client = useSelector(
    (state) =>
      state.clients.data.entities.clients[searchParams.get('clientId') || '']
  );
  //'\n\nPankaj Bhatia\nP. 647.640.2222\nE. info@pankajbhatia.ca';

  useEffect(() => {
    if (clientStatus === 'idle' || !client) {
      navigate('/dashboard/clients');
    } else {
      setSignature(
        `\n\n${toUpperCaseEachWord(client.name)}\n${phoneFormatter.format(
          client.cellNo,
          '(NNN) NNN-NNNN'
        )}\n${client.email}`
      );
    }
  }, [client]);

  return (
    <>
      <div className="p-3">
        <Typography variant="h1">Send SMS</Typography>
      </div>

      <div className="p-3">
        <Formik
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            clientApi
              .text(values)
              .then(() => {
                toast.success('Text message sent');
              })
              .catch((err) => {
                toast.error(err.message || 'Something went wrong');
              })
              .finally(() => setSubmitting(false));
          }}
          initialValues={{
            to: searchParams.get('to') || '',
            message: searchParams.get('msg') || ''
          }}
          validationSchema={Yup.object().shape({
            to: Yup.string()
              .matches(/^\d{10}$/, {
                message: 'enter a valid 10 digit only cell#'
              })
              .required('Phone# is required'),
            message: Yup.string().required('Message is required')
          })}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            touched,
            setFieldValue,
            isSubmitting,
            values
          }) => (
            <Form>
              <div className="max-w-md">
                <div className="mb-6">
                  <TextField
                    SelectProps={{ native: true }}
                    fullWidth
                    label="Template"
                    onBlur={handleBlur}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      const message = e.target.value + signature;
                      setFieldValue('message', message);
                      setMsgSegments(Math.ceil(message.length / 153));
                    }}
                    select
                    variant="outlined"
                  >
                    <option value="">Select template</option>
                    <option value="Have you reviewed the quote in the link?">
                      1st Reminder
                    </option>
                    <option value="You may be confused with many options; if you have any questions, Please call me.">
                      2nd Reminder
                    </option>
                    <option value="I hope I wasn't forgotten. Please don't hesitate to let me know if you need any help finalizing the insurance.">
                      3rd Reminder
                    </option>
                    <option value="I'm following up on the text link I sent you. I understand you are busy, but I would appreciate your review of the text link and respond whenever possible. I am excited to hear from you.">
                      4th Reminder
                    </option>
                    <option
                      value={`It was a pleasure talking to you. I need the following to complete the policy.

-Insured Name
-Insured Date of Birth
-Country of Origin
-Tentative or confirmed arrival date (Supervisa Insurance Only)
-Your Legal name for Beneficiary purposes
-Arrival date and Departure date (Visitors' Insurance)
-Email address for the policy
-Canadian Address

I'll make sure to call you for the credit card information.
`}
                    >
                      VTC Requirements
                    </option>
                    <option
                      value={`Thank you for trusting me with your insurance needs. Products we offer include:

Life insurance (Term, Universal Life, or Whole Life)
Critical Illness Insurance
Money Back Critical Illness Insurance
Disability Insurance
Supervisa Insurance
RESP
RRSP
TFSA

I would be glad to discuss any of the above with you.
`}
                    >
                      Services offered
                    </option>
                    <option value="I want to take a moment to say Happy Diwali to you and your family.">
                      Happy Diwali
                    </option>
                    <option value="I wish you and your family a happy holiday season. Wishing you a wonderful new year.">
                      Happy Holidays
                    </option>
                    <option value="Wherever you see a monthly premium, that company offers monthly payments.">
                      Monthly Plans
                    </option>
                    <option value="100% refund if Supervisa declined (Declined letter required) ">
                      Supervisa Declined
                    </option>
                    <option value="Use our plan comparison feature to find your best plan.">
                      Plan Comparison
                    </option>
                    <option value="Consider Deductible to lower the quote and save up to 45%">
                      Deductible
                    </option>
                    <option value="Date change is your responsibility. Please add a reminder to contact me before the effective date kicks in. Feel free to contact me through WhatsApp, call or text. If the Start date kicks in, the company will charge a $50 Date change fee.">
                      Date change
                    </option>
                    <option
                      value={`The early Cancellation fee is $50 (A picture of the boarding pass is required)
Supervisa Cancellation Fee is $150-$250 per person, depending on the company.
If Visa is Declined, the policy can be cancelled without penalty; Declined letter is required.`}
                    >
                      Cancellation fee
                    </option>
                    <option value="Please ensure the insured health remains the same as the arrival date; otherwise, the claim may be declined. Any changes in health, such as a change in medication, New medication, Increase or decrease in dosage, Stop of current medication, or New medication added, please let me know before the arrival date; to make changes in the policy.">
                      Stability Clause
                    </option>
                    <option value="Two months premium + one time Set up fee is required. The setup fee could be $50/ person, $60/person or $120 flat, depending on the company. If the claim is made against the policy, you need to pay all 12 months' payments unless you have taken the policy for Secure Travel or 21st Century.">
                      Monthly Plan
                    </option>
                  </TextField>
                </div>

                <div className="mb-6">
                  <TextField
                    defaultValue={values.to}
                    error={errors.to && touched.to}
                    fullWidth
                    helperText={errors.to && touched.to && errors.to}
                    label="Phone#"
                    name="to"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    variant="outlined"
                  />
                </div>

                <div className="mb-6">
                  <TextField
                    defaultValue={values.message}
                    error={errors.message && touched.message}
                    fullWidth
                    multiline
                    placeholder="Enter your message here"
                    InputLabelProps={{
                      shrink: true
                    }}
                    rows={6}
                    helperText={
                      errors.message && touched.message && errors.message
                    }
                    label="Message"
                    name="message"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value && e.target.value.length) {
                        setMsgSegments(Math.ceil(e.target.value.length / 153));
                      }
                    }}
                    variant="outlined"
                  />
                </div>
              </div>

              <Button
                color="primary"
                disableElevation
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                {isSubmitting ? 'Submitting' : 'Submit'}
              </Button>

              <Typography
                variant="body1"
                style={{ marginTop: '1rem', marginBottom: '1rem' }}
              >
                Estimated Message Count {msgSegments}
              </Typography>
            </Form>
          )}
        </Formik>
        {searchParams.get('to') && <Log phone={searchParams.get('to')} />}
      </div>
    </>
  );
};

export default Text;
