import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  uploadRatesTravelSingle,
  uploadRatesTravelMulti
} from 'features/plansTravel/plansTravelSlice';
import * as Yup from 'yup';
import { Button, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

const ImportSchema = Yup.object().shape({
  planId: Yup.string().required('Plan Id is required')
});

const RatesTravelImport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { planId, rateType } = useParams();
  const planStatus = useSelector((state) => state.plansTravel.status);
  const plan = useSelector(
    (state) => state.plansTravel.data.entities.plansTravel[planId]
  );

  // Load Plans if not loaded
  useEffect(() => {
    // check if we have plans
    if (planStatus === 'idle' || !plan) {
      navigate('/dashboard/plans-travel');
    }
  }, [planStatus]);

  return (
    <>
      <div className="p-3">
        <Typography variant="h1">
          Import rates - {rateType} trip {plan?.name}
        </Typography>
      </div>

      <div className="p-3">
        <p className="text-gray-600 my-3">Use CSV file for bulk imports.</p>

        {plan && (
          <Formik
            initialValues={{
              planId,
              planType: rateType,
              csv: ''
            }}
            validationSchema={ImportSchema}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(
                rateType === 'single'
                  ? uploadRatesTravelSingle(values)
                  : uploadRatesTravelMulti(values)
              )
                .unwrap()
                .then(() => {
                  navigate(`/dashboard/rates-travel/${planId}/${rateType}`);
                })
                .catch((err) => console.error(err));
              /*if (planType === 'single') {
                importRatesSingle(values)
                  .then(() => {
                    history.push(`/rates-travel/${plan._id}/${planType}`);
                  })
                  .catch((err) => {
                    console.error(err);
                    toast.error('There was an error importing rates');
                  });
              } else if (planType === 'multi') {
                importRatesMulti(values)
                  .then(() => {
                    history.push(`/rates-travel/${plan._id}/${planType}`);
                  })
                  .catch((err) => {
                    console.error(err);
                    toast.error('There was an error importing rates');
                  });
              }
              setSubmitting(false);*/
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <div className="mb-6">
                  <label htmlFor="exampleFormControlFile1">CSV File</label>
                  <input
                    type="file"
                    name="csv"
                    className="form-control-file"
                    onChange={(e) => {
                      setFieldValue('csv', e.currentTarget.files[0]);
                    }}
                  />
                </div>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  disableElevation
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting ? 'importing..' : 'Import'}
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

/*function mapStateToProps(state, ownProps) {
  return {
    plan: state.plansTravelReducer.entities.plansTravel?.[
      ownProps.match.params.planId
    ],
    planType: ownProps.match.params.planType,
    plansTravel: state.plansTravelReducer
  };
}

const mapDispatchToProps = {
  loadPlansTravel,
  importRatesSingle: rateTravelActions.importRatesTravelSingle,
  importRatesMulti: rateTravelActions.importRatesTravelMulti
};*/

export default RatesTravelImport;
