import { configureStore, combineReducers } from '@reduxjs/toolkit';
import clients from 'features/clients/clientsSlice';
import general from 'features/general/generalSlice';
import plans from 'features/plans/plansSlice';
import planDetails from 'features/planDetails/planDetailsSlice';
import planMetaVisitors from 'features/planMetaVisitor/planMetaVisitorSlice';
import planMetaStudents from 'features/planMetaStudent/planMetaStudentSlice';
import plansTravel from 'features/plansTravel/plansTravelSlice';
import quoteRequests from 'features/quoteRequests/quoteRequestsSlice';
import users from 'features/users/usersSlice';
import shortUrls from 'features/shortUrls/shortUrlsSlice';

const combinedReducer = combineReducers({
  clients,
  general,
  plans,
  planDetails,
  planMetaVisitors,
  planMetaStudents,
  quoteRequests,
  plansTravel,
  users,
  shortUrls
});

export default configureStore({
  reducer: combinedReducer
});
