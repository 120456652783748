import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { uploadAffiliates } from 'features/clients/clientsSlice';

const ImportSchema = Yup.object().shape({
  clientId: Yup.string().required('Client Id is required')
});

const ClientAffiliatesImport = ({ importClientAffiliates }) => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="p-3">
      <div className="mb-10">
        <Typography variant="h1">Import Affiliates</Typography>
      </div>
      <p className="text-muted text-gray-600">Use CSV file for bulk imports.</p>
      <Formik
        initialValues={{
          clientId,
          csv: ''
        }}
        validationSchema={ImportSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(uploadAffiliates(values))
            .unwrap()
            .then(() => {
              navigate(`/dashboard/clients/${clientId}`);
            })
            .catch((err) => console.error(err));
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <div className="mb-3">
              <label htmlFor="exampleFormControlFile1">CSV File</label>
              <input
                type="file"
                name="csv"
                className="form-control-file"
                onChange={(e) => {
                  setFieldValue('csv', e.currentTarget.files[0]);
                }}
              />
            </div>
            <Button
              color="primary"
              disabled={isSubmitting}
              disableElevation
              type="submit"
              variant="contained"
            >
              {isSubmitting ? 'importing..' : 'Import'}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/*function mapStateToProps(state, ownProps) {
  const clientId = ownProps.match.params.clientId;
  const client =
    state.clientReducer.entities.clients &&
    state.clientReducer.entities.clients[clientId];

  if (typeof client === 'undefined') {
    ownProps.history.push('/clients');
  }

  return {
    client,
    clients: state.clientReducer
  };
}

const mapDispatchToProps = {
  importClientAffiliates: clientActions.importClientAffiliates
};*/

export default ClientAffiliatesImport;
