import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  navOpen: true
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    // state here is the slice state and not the root state
    setNavOpen: (state, action) => {
      state.navOpen = action.payload;
    }
  }
});

export const { setNavOpen } = generalSlice.actions;

export default generalSlice.reducer;
