import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

const Single = ({ result, rates }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Age</TableCell>
          <TableCell>Days</TableCell>
          <TableCell>USA Single</TableCell>
          <TableCell>USA Couple</TableCell>
          <TableCell>USA Family</TableCell>
          <TableCell>CA Single</TableCell>
          <TableCell>CA Couple</TableCell>
          <TableCell>CA Family</TableCell>
          <TableCell>WW Single</TableCell>
          <TableCell>WW Couple</TableCell>
          <TableCell>WW Family</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {result.map((idx) => (
          <TableRow key={idx}>
            <TableCell>
              {rates[idx]?.ageMin}-{rates[idx]?.ageMax}
            </TableCell>
            <TableCell>
              {rates[idx]?.daysMin}-{rates[idx]?.daysMax}
            </TableCell>
            <TableCell>{rates[idx]?.rateUSASingle}</TableCell>
            <TableCell>{rates[idx]?.rateUSACouple}</TableCell>
            <TableCell>{rates[idx]?.rateUSAFamily}</TableCell>
            <TableCell>{rates[idx]?.rateCASingle}</TableCell>
            <TableCell>{rates[idx]?.rateCACouple}</TableCell>
            <TableCell>{rates[idx]?.rateCAFamily}</TableCell>
            <TableCell>{rates[idx]?.rateWWSingle}</TableCell>
            <TableCell>{rates[idx]?.rateWWCouple}</TableCell>
            <TableCell>{rates[idx]?.rateWWFamily}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Single;
