import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import { getPlans, planStatus, savePlan } from 'api/planApi';
import { getRates, deleteAllRates, importRates } from 'api/rateApi';
import { toast } from 'react-toastify';
import { uploadRatesTravelMulti } from '../plans/plansSlice';

const deductibleSchema = new schema.Entity(
  'deductibles',
  {},
  { idAttribute: '_id' }
);
const rateSchema = new schema.Entity('rates', {}, { idAttribute: '_id' });
const plansProcessStrategy = (value) => {
  return { ...value, rates: [], status: 'idle', error: null };
};

const plans = new schema.Entity(
  'plans',
  { deductibles: [deductibleSchema], rates: [rateSchema] },
  { idAttribute: '_id', processStrategy: plansProcessStrategy }
);
const plansSchema = [plans];

export const fetchPlans = createAsyncThunk(
  'plans/fetchPlans',
  async () => await getPlans()
);

export const addPlan = createAsyncThunk(
  'plans/addPlan',
  async (plan) => await savePlan(plan)
);

export const editPlan = createAsyncThunk(
  'plans/editPlan',
  async (plan) => await savePlan(plan)
);

export const updatePlanStatus = createAsyncThunk(
  'plans/updatePlanStatus',
  async (arg) => await planStatus(arg)
);

export const fetchRates = createAsyncThunk(
  'plans/fetchRates',
  async (arg) => await getRates(arg)
);

export const uploadRates = createAsyncThunk(
  'plans/uploadRates',
  async (arg) => await importRates(arg)
);

export const removeRates = createAsyncThunk(
  'plans/removeRates',
  async (plan) => await deleteAllRates(plan)
);

export const plansSlice = createSlice({
  name: 'plans',
  initialState: {
    data: { entities: { plans: {}, rates: {} }, result: [] },
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlans.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        const normalizedPlans = normalize(action.payload, plansSchema);
        state.data = normalizedPlans;
        state.data.result = normalizedPlans.result || [];
        state.data.entities.plans = normalizedPlans.entities.plans || {};
      })
      .addCase(fetchPlans.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(action.error.message || 'There was an error loading plans');
      })

      .addCase(addPlan.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(addPlan.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.data.entities.plans[action.payload._id] = action.payload;
        state.data.result.unshift(action.payload._id);
      })
      .addCase(addPlan.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(editPlan.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(editPlan.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.data.entities.plans[action.payload._id] = action.payload;
      })
      .addCase(editPlan.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(updatePlanStatus.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updatePlanStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        if (state.data.entities.plans[action.meta.arg.planId]) {
          state.data.entities.plans[action.meta.arg.planId].isActive =
            !!action.meta.arg.status;

          toast.success('Plan status updated successfully');
        }
      })
      .addCase(updatePlanStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(action.error.message || 'Failed to update plan status');
      })

      .addCase(fetchRates.pending, (state, action) => {
        if (state.data.entities.plans[action.meta.arg.planId]) {
          state.data.entities.plans[action.meta.arg.planId].status = 'loading';
          state.data.entities.plans[action.meta.arg.planId].error = null;
        }
      })
      .addCase(fetchRates.fulfilled, (state, action) => {
        const normalizedRates = normalize(action.payload, [rateSchema]);
        if (state.data.entities.plans[action.meta.arg.planId]) {
          state.data.entities.plans[action.meta.arg.planId].status =
            'succeeded';
          state.data.entities.plans[action.meta.arg.planId].error = null;

          state.data.entities.plans[action.meta.arg.planId].rates =
            normalizedRates.result;
          state.data.entities.rates = {
            ...state.data.entities.rates,
            ...normalizedRates.entities.rates
          };
        }
      })
      .addCase(fetchRates.rejected, (state, action) => {
        if (state.data.entities.plans[action.meta.arg.planId]) {
          state.data.entities.plans[action.meta.arg.planId].status = 'failed';
          state.data.entities.plans[action.meta.arg.planId].error =
            action.error.message;
        }
        toast.error(action.error.message || 'Failed to load plan rates');
      })

      .addCase(uploadRates.pending, (state, action) => {
        if (state.data.entities.plans[action.meta.arg.planId]) {
          state.data.entities.plans[action.meta.arg.planId].status = 'loading';
          state.data.entities.plans[action.meta.arg.planId].error = null;
        }
      })
      .addCase(uploadRates.fulfilled, (state, action) => {
        if (state.data.entities.plans[action.meta.arg.planId]) {
          state.data.entities.plans[action.meta.arg.planId].status =
            'succeeded';
          state.data.entities.plans[action.meta.arg.planId].error = null;

          const normalizedRates = normalize(action.payload.saved, [rateSchema]);
          state.data.entities.plans[action.meta.arg.planId].rates =
            normalizedRates.result;
          state.data.entities.rates = {
            ...state.data.entities.rates,
            ...normalizedRates.entities.rates
          };
        }
      })
      .addCase(uploadRates.rejected, (state, action) => {
        if (state.data.entities.plans[action.meta.arg.planId]) {
          state.data.entities.plans[action.meta.arg.planId].status = 'failed';
          state.data.entities.plans[action.meta.arg.planId].error =
            action.error.message;
        }
        toast.error(action.error.message || 'Failed to import plan rates');
      })

      .addCase(removeRates.pending, (state, action) => {
        if (state.data.entities.plans[action.meta.arg]) {
          state.data.entities.plans[action.meta.arg].status = 'loading';
          state.data.entities.plans[action.meta.arg].error = null;
        }
      })
      .addCase(removeRates.fulfilled, (state, action) => {
        if (state.data.entities.plans[action.meta.arg._id]) {
          state.data.entities.plans[action.meta.arg._id].status = 'succeeded';
          state.data.entities.plans[action.meta.arg._id].error = null;
          state.data.entities.plans[action.meta.arg._id].rates = [];
          /*
           do not touch this as it has other plans rates too;
          state.data.entities.rates = {};*/
        }
      })
      .addCase(removeRates.rejected, (state, action) => {
        if (state.data.entities.plans[action.meta.arg]) {
          state.data.entities.plans[action.meta.arg].status = 'failed';
          state.data.entities.plans[action.meta.arg].error =
            action.error.message;
        }
        toast.error(action.error.message || 'Failed to delete plan rates');
      });
  }
});

export default plansSlice.reducer;
