import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, TextField } from '@mui/material';
import { Header, Form as PlanForm } from './components';
import { PlanTypes } from '../../utilities/config';
import { addPlan } from 'features/plans/plansSlice';
import { useNavigate } from 'react-router-dom';

const PlansSchema = Yup.object().shape({
  name: Yup.string().required('Plan name is required'),
  isActive: Yup.bool().required('Please select status'),
  coupleDiscount: Yup.number()
    .min(0)
    .lessThan(100)
    .required('Couple Discount is required'),
  coupleFactor: Yup.number()
    .moreThan(0)
    .max(1)
    .required('Couple factor is required'),
  superCoupleMonthlyAdjustment: Yup.number().required(
    'Super Couple Monthly Adjustment is required'
  ),
  maxCost: Yup.number()
    .moreThan(0)
    .required('maxCost is required. For visitors enter any random number.'),
  deductibleType: Yup.string().required(
    'Select what type of deductible is offered'
  ),
  haveMonthlyPlansVisitor: Yup.boolean().required,
  monthlyPlansVisitorMinDays: Yup.number().when('haveMonthlyPlansVisitor', {
    is: 'true',
    then: Yup.number().min(1).required('Required')
  }),
  monthlyPlansVisitorMinAmount: Yup.number().when('haveMonthlyPlansVisitor', {
    is: 'true',
    then: Yup.number().min(1).required('Required')
  }),
  monthlyPlansVisitorFactor: Yup.number().when('haveMonthlyPlansVisitor', {
    is: 'true',
    then: Yup.number().min(1).required('Required')
  }),
  haveMonthlyPlans: Yup.boolean().required(
    'Select if monthly plans are available or not'
  ),
  ageCalculation: Yup.string().required(
    'Please select how the Age will be calculated'
  )
});

const PlanVisitorAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className="p-3">
        <Header />
      </div>
      <div className="p-3 max-w-lg">
        <Formik
          initialValues={{
            planType: 0,
            name: '',
            isActive: true,
            coupleDiscount: /*plan && plan.coupleFactor
                  ? (100 - plan.coupleFactor * 100).toFixed(2)
                  : 0*/ 0,
            coupleFactor: 1,
            superCoupleMonthlyAdjustment: 0,
            haveMonthlyPlansVisitor: 0,
            monthlyPlansVisitorMinDays: '',
            monthlyPlansVisitorMinAmount: '',
            monthlyPlansVisitorFactor: 1,
            haveMonthlyPlans: '',
            deductibleType: '',
            ageCalculation: '',
            summary: '',
            minCost: 1,
            maxCost: 1,
            planDetailsId: '',
            buyDirectUrl: '',
            claimFormUrl: '',
            policyWordingUrl: ''
          }}
          validationSchema={PlansSchema}
          onSubmit={(
            {
              haveMonthlyPlansVisitor,
              monthlyPlansVisitorMinDays,
              monthlyPlansVisitorMinAmount,
              monthlyPlansVisitorFactor,
              ...rest
            },
            { setSubmitting }
          ) => {
            dispatch(
              addPlan({
                ...rest,
                ...(Number(haveMonthlyPlansVisitor)
                  ? { monthlyPlansVisitorMinDays }
                  : {}),
                ...(Number(haveMonthlyPlansVisitor)
                  ? { monthlyPlansVisitorMinAmount }
                  : {}),
                ...(Number(haveMonthlyPlansVisitor)
                  ? { monthlyPlansVisitorFactor }
                  : {}),
                haveMonthlyPlansVisitor: !!Number(haveMonthlyPlansVisitor)
              })
            )
              .unwrap()
              .then(() => {
                navigate('/dashboard/plans-visitor');
              })
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue,
            touched,
            values
          }) => (
            <Form>
              <div className="mb-6 w-48">
                <TextField
                  SelectProps={{ native: true }}
                  defaultValue={values.planType}
                  error={errors.planType && touched.planType}
                  fullWidth
                  helperText={
                    errors.planType && touched.planType && errors.planType
                  }
                  label="Plan Type"
                  name="planType"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  variant="outlined"
                >
                  {Object.keys(PlanTypes).map((value, index) => (
                    <option key={value} value={value}>
                      {PlanTypes[index]}
                    </option>
                  ))}
                </TextField>
              </div>

              <div className="mb-6 w-48">
                <TextField
                  SelectProps={{ native: true }}
                  defaultValue={values.isActive}
                  error={errors.isActive && touched.isActive}
                  fullWidth
                  helperText={
                    errors.isActive && touched.isActive && errors.isActive
                  }
                  label="Status"
                  name="isActive"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  variant="outlined"
                >
                  <option value=""></option>
                  <option value="true">Active</option>
                  <option value="false">De-active</option>
                </TextField>
              </div>

              <div className="mb-6">
                <TextField
                  defaultValue={values.name}
                  error={errors.name && touched.name}
                  fullWidth
                  helperText={errors.name && touched.name && errors.name}
                  label="Plan Name"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                />
              </div>

              <PlanForm
                {...{
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  values
                }}
              />

              <Button
                color="primary"
                disableElevation
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                {isSubmitting ? 'Saving' : 'Save'}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PlanVisitorAdd;
