import React from 'react';
import { Link } from 'react-router-dom';
import { Dashboard } from '../../layouts';

const Home = () => (
  <Dashboard>
    <div className="jumbotron">
      <h1>Welcome</h1>
      <p>welcome to Insure In Canada Inc.</p>
      <Link to="plans" className="btn btn-primary btn-lg">
        plans
      </Link>
    </div>
  </Dashboard>
);

export default Home;
