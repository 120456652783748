import React, { useEffect } from 'react';
import { RouterLink } from 'components';
import { Button } from '@mui/material';

const Unauthorized = () => {
  useEffect(() => {
    window.localStorage.removeItem('user');
  }, []);
  return (
    <div className="text-center">
      <h1 className="text-3xl font-bold mb-3">Access Denied</h1>
      <p className="mb-6">
        The requested resource is protected. You must login to continue.
      </p>
      <div className="text-center">
        <Button
          color="primary"
          component={RouterLink}
          disableElevation
          to="/login"
          variant="contained"
        >
          Log In
        </Button>
      </div>
    </div>
  );
};

export default Unauthorized;
