import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRates } from 'features/plans/plansSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { Header, Visitor, Student } from './components';
import Spinner from '../../components/Spinner';
import { Alert } from '@mui/material';

const Rates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { planId } = useParams();
  const planStatus = useSelector((state) => state.plans.status);
  const plan = useSelector((state) => {
    if (planId && state.plans.data.entities.plans[planId]) {
      return state.plans.data.entities.plans[planId];
    }
  });
  const ratesStatus = useSelector((state) => {
    if (planId && state.plans.data.entities.plans[planId]?.status) {
      return state.plans.data.entities.plans[planId].status;
    }
  });
  const rates = useSelector((state) => state.plans.data.entities.rates);

  useEffect(() => {
    if (planStatus === 'idle') {
      navigate('/dashboard/plans');
    }
  }, [planStatus]);

  useEffect(() => {
    // check if we have plans
    if (ratesStatus === 'idle') {
      dispatch(fetchRates({ planId, planType: plan?.planType }));
    }
  }, [ratesStatus]);

  /*const handleDeleteAll = (plan) => {
    deleteAllRates(plan);
  };*/

  return (
    <>
      <Header plan={plan} />
      {ratesStatus === 'loading' && <Spinner />}

      <div className="p-3">
        {plan?.rates && plan.rates.length ? (
          <>
            <p className="mb-6">{plan.rates.length} records found</p>
            {plan.planType === 3 ? (
              <Student rates={rates} plan={plan} />
            ) : (
              <Visitor rates={rates} plan={plan} />
            )}
          </>
        ) : (
          <div className="my-3">
            <Alert severity="warning">0 rates</Alert>
          </div>
        )}
      </div>
    </>
  );
};

export default Rates;
