import React from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { uploadMetaVisitors } from 'features/planMetaVisitor/planMetaVisitorSlice';
import { uploadMetaStudents } from 'features/planMetaStudent/planMetaStudentSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

const PlansMetaVisitorImport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type } = useParams();

  return (
    <>
      <div className="p-3">
        <Typography variant="h1">Import Plan Meta (Visitor)</Typography>
      </div>

      <div className="p-3">
        <p className="text-gray-600 py-3">Use CSV file for bulk imports.</p>
        <Formik
          initialValues={{
            csv: ''
          }}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(
              type === 'visitor'
                ? uploadMetaVisitors(values)
                : uploadMetaStudents(values)
            )
              .unwrap()
              .then(() => {
                navigate(`/dashboard/plan-details/${type}`);
              })
              .catch((err) => console.error(err))
              .finally(() => setSubmitting(false));
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className="mb-6">
                <label htmlFor="exampleFormControlFile1">CSV File</label>
                <input
                  type="file"
                  name="csv"
                  className="form-control-file"
                  onChange={(e) => {
                    setFieldValue('csv', e.currentTarget.files[0]);
                  }}
                />
              </div>
              <Button
                color="primary"
                disabled={isSubmitting}
                disableElevation
                type="submit"
                variant="contained"
              >
                {isSubmitting ? 'importing..' : 'Import'}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PlansMetaVisitorImport;
