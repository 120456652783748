import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, TextField } from '@mui/material';
import { Header, Form as PlanForm } from './components';
import { useNavigate } from 'react-router-dom';
import { addPlanTravel } from 'features/plansTravel/plansTravelSlice';

const PlansSchema = Yup.object().shape({
  name: Yup.string().required('Plan name is required'),
  isActive: Yup.bool().required('Please select status'),
  minCost: Yup.number().moreThan(0).required('minCost is required.')
});

const PlanTravelAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className="p-3">
        <Header />
      </div>

      <div className="p-3 max-w-md">
        <Formik
          initialValues={{
            name: '',
            isActive: true,
            summary: '',
            minCost: 1,
            planDetailsId: ''
          }}
          validationSchema={PlansSchema}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(addPlanTravel(values))
              .unwrap()
              .then(() => navigate('/dashboard/plans-travel'))
              .catch((err) => console.error(err))
              .finally(() => setSubmitting(false));
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue,
            touched,
            values
          }) => (
            <Form>
              <div className="mb-6">
                <TextField
                  SelectProps={{ native: true }}
                  defaultValue={values.isActive}
                  error={errors.isActive && touched.isActive}
                  fullWidth
                  helperText={
                    errors.isActive && touched.isActive && errors.isActive
                  }
                  label="Status"
                  name="isActive"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  variant="outlined"
                >
                  <option value=""></option>
                  <option value="true">Active</option>
                  <option value="false">De-active</option>
                </TextField>
              </div>

              <div className="mb-6">
                <TextField
                  defaultValue={values.name}
                  error={errors.name && touched.name}
                  fullWidth
                  helperText={errors.name && touched.name && errors.name}
                  label="Plan Name"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                />
              </div>

              <PlanForm
                {...{
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  values
                }}
              />

              <Button
                color="primary"
                disableElevation
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                {isSubmitting ? 'Saving' : 'Save'}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PlanTravelAdd;
