import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlanMetaVisitors } from 'features/planMetaVisitor/planMetaVisitorSlice';
import { fetchPlanMetaStudents } from 'features/planMetaStudent/planMetaStudentSlice';
import { Header, List } from './components';
import Spinner from '../../components/Spinner/Spinner';
import { Alert } from '@mui/material';
import { useParams } from 'react-router-dom';

const PlansMetaVisitor = () => {
  const dispatch = useDispatch();
  const { type } = useParams();
  const planMetaStatus = useSelector((state) =>
    type === 'visitor'
      ? state.planMetaVisitors.status
      : state.planMetaStudents.status
  );
  const result = useSelector((state) =>
    type === 'visitor'
      ? state.planMetaVisitors.data.result
      : state.planMetaStudents.data.result
  );
  const planMeta = useSelector((state) =>
    type === 'visitor'
      ? state.planMetaVisitors.data.entities.planMetaVisitors
      : state.planMetaStudents.data.entities.planMetaVisitors
  );
  const plansVisitor = useSelector((state) => state.plans.data.entities.plans);

  useEffect(() => {
    if (planMetaStatus === 'idle') {
      dispatch(
        type === 'visitor' ? fetchPlanMetaVisitors() : fetchPlanMetaStudents()
      );
    }
  }, [planMetaStatus]);

  return (
    <>
      <Header type={type} />
      {planMetaStatus === 'loading' && <Spinner />}

      <div className="p-3">
        {result && result.length ? (
          <List planMeta={planMeta} result={result} plans={plansVisitor} />
        ) : (
          <div className="my-3">
            <Alert severity="warning">0 plans with meta details</Alert>
          </div>
        )}
      </div>
    </>
  );
};

export default PlansMetaVisitor;
