import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  Button,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { PlanTypes } from 'utilities/config';
import { toast } from 'react-toastify';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updatePlanStatus } from 'features/plans/plansSlice';

const List = ({ plans, result }) => {
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();

  return (
    <div>
      {result && result.length && (
        <>
          <Typography color="textSecondary" gutterBottom variant="body2">
            {result.length} records found.
          </Typography>

          <PerfectScrollbar>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>PlanID</TableCell>
                    <TableCell>Plan</TableCell>
                    <TableCell>Slug</TableCell>
                    <TableCell>Couple Dis.</TableCell>
                    <TableCell>Visitor Mth.</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result
                    .filter((item) => {
                      if (
                        searchParams.get('search') &&
                        searchParams.get('search') !== ''
                      ) {
                        const search = searchParams.get('search').toLowerCase();
                        if (plans[item].name.includes(search)) {
                          return item;
                        }
                      } else {
                        return item;
                      }
                    })
                    .map((key) => (
                      <TableRow hover key={key}>
                        <TableCell>{PlanTypes[plans[key].planType]}</TableCell>
                        <TableCell>
                          <Tooltip title="click to copy">
                            <IconButton
                              aria-label="Copy PlanID"
                              color="primary"
                              size="small"
                              onClick={() => {
                                navigator.clipboard
                                  .writeText(key)
                                  .then(() => {
                                    toast.success(
                                      'Plan ID copied to clipboard'
                                    );
                                  })
                                  .catch((err) => {
                                    toast.error(err);
                                  });
                              }}
                            >
                              <FileCopyIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Link
                            component={RouterLink}
                            to={`/dashboard/rates/${key}`}
                          >
                            {plans[key].name}
                          </Link>
                        </TableCell>
                        <TableCell>{plans[key].slug}</TableCell>
                        <TableCell>
                          {100 - plans[key].coupleFactor * 100}%
                        </TableCell>
                        <TableCell>
                          {plans[key].haveMonthlyPlansVisitor ? (
                            <>
                              min.{plans[key].monthlyPlansVisitorMinDays}days,
                              min.$
                              {plans[key].monthlyPlansVisitorMinAmount}@
                              {plans[key].monthlyPlansVisitorFactor}
                            </>
                          ) : (
                            <>-</>
                          )}
                        </TableCell>
                        <TableCell>
                          {plans[key].isActive ? (
                            <span style={{ color: '#090' }}>Active</span>
                          ) : (
                            <span style={{ color: '#f00' }}>Inactive</span>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              dispatch(
                                updatePlanStatus({
                                  planId: key,
                                  status: plans[key].isActive ? 0 : 1
                                })
                              );
                            }}
                            size="small"
                            variant="outlined"
                          >
                            {plans[key].isActive ? 'Deactivate' : 'Activate'}
                          </Button>{' '}
                          <Button
                            color="primary"
                            component={RouterLink}
                            size="small"
                            to={`${key}/update`}
                            variant="outlined"
                          >
                            Update
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </>
      )}
    </div>
  );
};

List.propTypes = {
  plans: PropTypes.object.isRequired
};

export default List;
