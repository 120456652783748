import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import {
  getPlansTravel,
  planTravelStatus,
  savePlanTravel
} from 'api/planTravelApi';
import { toast } from 'react-toastify';
import {
  getRatesTravelSingle,
  getRatesTravelMulti,
  importRatesTravelSingle,
  importRatesTravelMulti,
  deleteAllRates
} from 'api/rateTravelApi';
import { uploadAffiliates } from '../clients/clientsSlice';

const rateSingleSchema = new schema.Entity(
  'ratesSingle',
  {},
  { idAttribute: '_id' }
);
const rateMultiSchema = new schema.Entity(
  'ratesMulti',
  {},
  { idAttribute: '_id' }
);
const plansTravelProcessStrategy = (value) => {
  return {
    ...value,
    ratesSingle: [],
    ratesMulti: [],
    statusSingle: 'idle',
    errorSingle: null,
    statusMulti: 'idle',
    errorMulti: null
  };
};
const plansTravel = new schema.Entity(
  'plansTravel',
  { ratesSingle: [rateSingleSchema], ratesMulti: [rateMultiSchema] },
  { idAttribute: '_id', processStrategy: plansTravelProcessStrategy }
);
const plansTravelSchema = [plansTravel];

export const fetchPlansTravel = createAsyncThunk(
  'plansTravel/fetchPlansTravel',
  async () => await getPlansTravel()
);

export const addPlanTravel = createAsyncThunk(
  'plansTravel/addPlanTravel',
  async (plan) => await savePlanTravel(plan)
);

export const editPlanTravel = createAsyncThunk(
  'plansTravel/editPlanTravel',
  async (plan) => await savePlanTravel(plan)
);

export const updatePlanTravelStatus = createAsyncThunk(
  'plansTravel/updatePlanTravelStatus',
  async (arg) => await planTravelStatus(arg)
);

export const fetchRatesSingleTravel = createAsyncThunk(
  'plansTravel/fetchRatesSingleTravel',
  async (arg) => await getRatesTravelSingle(arg)
);

export const fetchRatesMultiTravel = createAsyncThunk(
  'plansTravel/fetchRatesMultiTravel',
  async (arg) => await getRatesTravelMulti(arg)
);

export const uploadRatesTravelSingle = createAsyncThunk(
  'plansTravel/uploadRatesTravelSingle',
  async (data) => await importRatesTravelSingle(data)
);

export const uploadRatesTravelMulti = createAsyncThunk(
  'plansTravel/uploadRatesTravelMulti',
  async (data) => await importRatesTravelMulti(data)
);

export const removeRatesTravel = createAsyncThunk(
  'plansTravel/removeRatesTravel',
  async (args) => await deleteAllRates(args)
);

export const plansTravelSlice = createSlice({
  name: 'plansTravel',
  initialState: {
    data: { entities: { plansTravel: {}, rates: {} }, result: [] },
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlansTravel.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchPlansTravel.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        const normalizedPlans = normalize(action.payload, plansTravelSchema);
        state.data = normalizedPlans;
        state.data.result = normalizedPlans.result || [];
        state.data.entities.plansTravel =
          normalizedPlans.entities.plansTravel || {};
      })
      .addCase(fetchPlansTravel.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(
          action.error.message || 'There was an error loading travel plans'
        );
      })

      .addCase(addPlanTravel.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(addPlanTravel.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.data.entities.plansTravel[action.payload._id] = action.payload;
        state.data.result.unshift(action.payload._id);
      })
      .addCase(addPlanTravel.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(editPlanTravel.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(editPlanTravel.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.data.entities.plansTravel[action.payload._id] = action.payload;
      })
      .addCase(editPlanTravel.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(updatePlanTravelStatus.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updatePlanTravelStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        if (state.data.entities.plansTravel[action.meta.arg.planId]) {
          state.data.entities.plansTravel[action.meta.arg.planId].isActive =
            !!action.meta.arg.status;

          toast.success('Travel plan status updated successfully');
        }
      })
      .addCase(updatePlanTravelStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(
          action.error.message || 'Failed to update travel plan status'
        );
      })

      .addCase(fetchRatesSingleTravel.pending, (state, action) => {
        if (state.data.entities.plansTravel[action.meta.arg]) {
          state.data.entities.plansTravel[action.meta.arg].statusSingle =
            'loading';
          state.data.entities.plansTravel[action.meta.arg].errorSingle = null;
        }
      })
      .addCase(fetchRatesSingleTravel.fulfilled, (state, action) => {
        const normalizedRates = normalize(action.payload, [rateSingleSchema]);
        if (state.data.entities.plansTravel[action.meta.arg]) {
          state.data.entities.plansTravel[action.meta.arg].statusSingle =
            'succeeded';
          state.data.entities.plansTravel[action.meta.arg].errorSingle = null;

          state.data.entities.plansTravel[action.meta.arg].ratesSingle =
            normalizedRates.result;
          state.data.entities.ratesSingle = {
            ...state.data.entities.ratesSingle,
            ...normalizedRates.entities.ratesSingle
          };
        }
      })
      .addCase(fetchRatesSingleTravel.rejected, (state, action) => {
        if (state.data.entities.plansTravel[action.meta.arg]) {
          state.data.entities.plansTravel[action.meta.arg].statusSingle =
            'failed';
          state.data.entities.plansTravel[action.meta.arg].errorSingle =
            action.error.message;
        }
        toast.error(
          action.error.message ||
            'Failed to load travel plan rates - single trip'
        );
      })

      .addCase(fetchRatesMultiTravel.pending, (state, action) => {
        if (state.data.entities.plansTravel[action.meta.arg]) {
          state.data.entities.plansTravel[action.meta.arg].statusMulti =
            'loading';
          state.data.entities.plansTravel[action.meta.arg].errorMulti = null;
        }
      })
      .addCase(fetchRatesMultiTravel.fulfilled, (state, action) => {
        const normalizedRates = normalize(action.payload, [rateMultiSchema]);
        if (state.data.entities.plansTravel[action.meta.arg]) {
          state.data.entities.plansTravel[action.meta.arg].statusMulti =
            'succeeded';
          state.data.entities.plansTravel[action.meta.arg].errorMulti = null;

          state.data.entities.plansTravel[action.meta.arg].ratesMulti =
            normalizedRates.result;
          state.data.entities.ratesMulti = {
            ...state.data.entities.ratesMulti,
            ...normalizedRates.entities.ratesMulti
          };
        }
      })
      .addCase(fetchRatesMultiTravel.rejected, (state, action) => {
        if (state.data.entities.plansTravel[action.meta.arg]) {
          state.data.entities.plansTravel[action.meta.arg].statusMulti =
            'failed';
          state.data.entities.plansTravel[action.meta.arg].errorMulti =
            action.error.message;
        }
        toast.error(
          action.error.message ||
            'Failed to load travel plan rates - multi trip'
        );
      })

      .addCase(uploadRatesTravelSingle.pending, (state, action) => {
        if (state.data.entities.plansTravel[action.meta.arg.planId]) {
          state.data.entities.plansTravel[action.meta.arg.planId].statusSingle =
            'loading';
          state.data.entities.plansTravel[action.meta.arg.planId].errorSingle =
            null;
        }
      })
      .addCase(uploadRatesTravelSingle.fulfilled, (state, action) => {
        if (state.data.entities.plansTravel[action.meta.arg.planId]) {
          state.data.entities.plansTravel[action.meta.arg.planId].statusSingle =
            'succeeded';
          state.data.entities.plansTravel[action.meta.arg.planId].errorSingle =
            null;

          const normalizedRates = normalize(action.payload.saved, [
            rateSingleSchema
          ]);
          state.data.entities.plansTravel[action.meta.arg.planId].ratesSingle =
            normalizedRates.result;
          state.data.entities.ratesSingle = {
            ...state.data.entities.ratesSingle,
            ...normalizedRates.entities.ratesSingle
          };
        }
      })
      .addCase(uploadRatesTravelSingle.rejected, (state, action) => {
        if (state.data.entities.plansTravel[action.meta.arg.planId]) {
          state.data.entities.plansTravel[action.meta.arg.planId].statusSingle =
            'failed';
          state.data.entities.plansTravel[action.meta.arg.planId].errorSingle =
            action.error.message;
        }
        toast.error(
          action.error.message ||
            'Failed to import travel plan rates - single trip'
        );
      })

      .addCase(uploadRatesTravelMulti.pending, (state, action) => {
        if (state.data.entities.plansTravel[action.meta.arg.planId]) {
          state.data.entities.plansTravel[action.meta.arg.planId].statusMulti =
            'loading';
          state.data.entities.plansTravel[action.meta.arg.planId].errorMulti =
            null;
        }
      })
      .addCase(uploadRatesTravelMulti.fulfilled, (state, action) => {
        if (state.data.entities.plansTravel[action.meta.arg.planId]) {
          state.data.entities.plansTravel[action.meta.arg.planId].statusMulti =
            'succeeded';
          state.data.entities.plansTravel[action.meta.arg.planId].errorMulti =
            null;

          const normalizedRates = normalize(action.payload.saved, [
            rateMultiSchema
          ]);
          state.data.entities.plansTravel[action.meta.arg.planId].ratesMulti =
            normalizedRates.result;
          state.data.entities.ratesMulti = {
            ...state.data.entities.ratesMulti,
            ...normalizedRates.entities.ratesMulti
          };
        }
      })
      .addCase(uploadRatesTravelMulti.rejected, (state, action) => {
        if (state.data.entities.plansTravel[action.meta.arg.planId]) {
          state.data.entities.plansTravel[action.meta.arg.planId].statusMulti =
            'failed';
          state.data.entities.plansTravel[action.meta.arg.planId].errorMulti =
            action.error.message;
        }
        toast.error(
          action.error.message ||
            'Failed to import travel plan rates - multi trip'
        );
      })

      .addCase(removeRatesTravel.pending, (state, action) => {
        if (state.data.entities.plansTravel[action.meta.arg.planId]) {
          state.data.entities.plansTravel[action.meta.arg.planId][
            action.meta.arg.rateType === 'single'
              ? 'statusSingle'
              : 'statusMulti'
          ] = 'loading';
          state.data.entities.plansTravel[action.meta.arg.planId][
            action.meta.arg.rateType === 'single' ? 'errorSingle' : 'errorMulti'
          ] = null;
        }
      })
      .addCase(removeRatesTravel.fulfilled, (state, action) => {
        if (state.data.entities.plansTravel[action.meta.arg.planId]) {
          state.data.entities.plansTravel[action.meta.arg.planId][
            action.meta.arg.rateType === 'single'
              ? 'statusSingle'
              : 'statusMulti'
          ] = 'succeeded';
          state.data.entities.plansTravel[action.meta.arg.planId][
            action.meta.arg.rateType === 'single' ? 'errorSingle' : 'errorMulti'
          ] = null;
          state.data.entities.plansTravel[action.meta.arg.planId][
            action.meta.arg.rateType === 'single' ? 'ratesSingle' : 'ratesMulti'
          ] = [];
          /*
           do not touch this as it has other plans rates too;
          state.data.entities[
            action.meta.arg.rateType === 'single' ? 'ratesSingle' : 'ratesMulti'
          ] = {};*/
        }
      })
      .addCase(removeRatesTravel.rejected, (state, action) => {
        if (state.data.entities.plansTravel[action.meta.arg.planId]) {
          state.data.entities.plansTravel[action.meta.arg.planId][
            action.meta.arg.rateType === 'single'
              ? 'statusSingle'
              : 'statusMulti'
          ] = 'failed';
          state.data.entities.plansTravel[action.meta.arg.planId][
            action.meta.arg.rateType === 'single' ? 'errorSingle' : 'errorMulti'
          ] = action.error.message;
        }
        toast.error(
          action.error.message || 'Failed to delete travel plan rates'
        );
      });
  }
});

export default plansTravelSlice.reducer;
