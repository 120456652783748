import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import {
  getPlanDetails,
  savePlanDetail,
  updatePlanDetail,
  updatePlanDetailField,
  getPlanDetail
} from 'api/planSummaryApi';
import { toast } from 'react-toastify';

const planDetails = new schema.Entity(
  'planDetails',
  {},
  { idAttribute: '_id' }
);
const planDetailsSchema = [planDetails];

export const fetchPlanDetails = createAsyncThunk(
  'planDetails/fetchPlanDetails',
  async () => await getPlanDetails()
);

export const fetchPlanDetail = createAsyncThunk(
  'planDetails/fetchPlanDetail',
  async (planDetailId) => await getPlanDetail(planDetailId)
);

export const addPlanDetails = createAsyncThunk(
  'planDetails/addPlanDetails',
  async (planDetails) => await savePlanDetail(planDetails)
);

export const editPlanDetails = createAsyncThunk(
  'planDetails/editPlanDetails',
  async (planDetails) => await updatePlanDetail(planDetails)
);

export const editPlanDetailsField = createAsyncThunk(
  'planDetails/editPlanDetailsField',
  async (planDetails) => await updatePlanDetailField(planDetails)
);

export const planDetailsSlice = createSlice({
  name: 'planDetails',
  initialState: {
    data: { entities: { planDetails: {} }, result: [] },
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlanDetails.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchPlanDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        const normalizedPlanDetails = normalize(
          action.payload,
          planDetailsSchema
        );
        state.data = normalizedPlanDetails;
        state.data.result = normalizedPlanDetails.result || [];
        state.data.entities.planDetails =
          normalizedPlanDetails.entities.planDetails || {};
      })
      .addCase(fetchPlanDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(
          action.error.message || 'There was an error loading planDetails'
        );
      })

      .addCase(fetchPlanDetail.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchPlanDetail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        if (state.data.entities.planDetails[action.meta.arg]) {
          state.data.entities.planDetails[action.meta.arg] = {
            ...state.data.entities.planDetails[action.meta.arg],
            ...action.payload
          };
        }
      })
      .addCase(fetchPlanDetail.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(
          action.error.message || 'There was an error loading plan detail'
        );
      })

      .addCase(addPlanDetails.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(addPlanDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.data.entities.planDetails[action.payload._id] = action.payload;
        state.data.result.unshift(action.payload._id);
      })
      .addCase(addPlanDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(editPlanDetails.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(editPlanDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.data.entities.planDetails[action.payload._id] = action.payload;
      })
      .addCase(editPlanDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(editPlanDetailsField.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(editPlanDetailsField.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.data.entities.planDetails[action.payload._id] = action.payload;
      })
      .addCase(editPlanDetailsField.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default planDetailsSlice.reducer;
