import axios from './axios';

const baseUrl = '/clients';

export function getClients(params = {}) {
  return axios({
    method: 'get',
    url: baseUrl,
    params
  });
}

export function deleteClient(clientId) {
  return axios({
    method: 'delete',
    url: `${baseUrl}/${clientId}`
  });
}

export function importClientAffiliates(input) {
  let data = new FormData();
  data.append('csv', input.csv);
  data.append('clientId', input.clientId);

  return axios({
    method: 'post',
    url: `${baseUrl}/affiliates/import`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  });
}

export function text(data) {
  return axios({
    method: 'post',
    url: `${baseUrl}/text`,
    data
  });
}

export function log(phone) {
  return axios({
    method: 'get',
    url: `${baseUrl}/log/?phone=${phone}`
  });
}

export function saveClient(data) {
  return axios({
    method: data.clientId ? 'put' : 'post',
    url: data.clientId ? `${baseUrl}/${data.clientId}` : baseUrl,
    data: { ...data, _id: data.clientId }
  });
}
