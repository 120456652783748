import React from 'react';
import { Header, List } from './components';
import Spinner from 'components/Spinner/Spinner';
import { Alert } from '@mui/material';
import { usePlans } from 'hooks';

const PlansVisitor = () => {
  const { status, error, plans, result } = usePlans();

  return (
    <>
      <Header />
      <div className="p-3">
        {status === 'loading' && <Spinner />}
        {error && (
          <div className="my-3">
            <Alert severity="warning">{error}</Alert>
          </div>
        )}
        {result && result.length ? (
          <List result={result} plans={plans} />
        ) : (
          <div className="my-3">
            <Alert severity="info">0 records</Alert>
          </div>
        )}
      </div>
    </>
  );
};

export default PlansVisitor;
