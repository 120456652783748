import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

const List = ({ planMeta, result, plans }) => {
  return (
    <div>
      {result && result.length && (
        <>
          <Typography color="textSecondary" gutterBottom variant="body2">
            {result.length} records found.
          </Typography>

          <PerfectScrollbar>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Plan/Keys</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result.map((key) => (
                    <TableRow hover key={key}>
                      <TableCell>
                        {plans?.[planMeta?.[key]?.planId]
                          ? plans[planMeta[key].planId].name
                          : key}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </>
      )}
    </div>
  );
};

export default List;
