import React from 'react';
import PropTypes from 'prop-types';
import phoneFormatter from 'phone-formatter';

const PhoneFormat = ({ number }) => {
  if (!number) {
    return <></>;
  } else {
    return phoneFormatter.format(number, '(NNN) NNN-NNNN');
  }
};

PhoneFormat.propTypes = {
  value: PropTypes.string
};

export default PhoneFormat;
