import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Header } from './components';
import Spinner from '../../components/Spinner/Spinner';
import { fetchPlanDetails } from 'features/planDetails/planDetailsSlice';
import { Alert } from '@mui/material';

const PlanSummaries = () => {
  const dispatch = useDispatch();
  const planDetailsStatus = useSelector((state) => state.planDetails.status);
  const result = useSelector((state) => state.planDetails.data.result);
  const planDetails = useSelector(
    (state) => state.planDetails.data.entities.planDetails
  );

  useEffect(() => {
    if (planDetailsStatus === 'idle') {
      dispatch(fetchPlanDetails());
    }
  }, [planDetailsStatus]);

  return (
    <>
      <Header />
      {planDetailsStatus === 'loading' && <Spinner />}

      {result && result.length ? (
        <List planDetails={planDetails} result={result} />
      ) : (
        <div className="my-3">
          <Alert severity="warning">0 plan summary records</Alert>
        </div>
      )}
    </>
  );
};

export default PlanSummaries;
