import React, { useEffect } from 'react';
import { RouterLink } from 'components';
import { Button, Typography } from '@mui/material';

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem('user');
  }, []);

  return (
    <>
      <Typography align="center" gutterBottom variant="h2">
        Logged out
      </Typography>
      <div className="mb-6">
        <Typography align="center">
          You have successfully logged out of the dashboard.
        </Typography>
      </div>
      <div className="text-center">
        <Button
          color="primary"
          component={RouterLink}
          disableElevation
          to="/login"
          variant="contained"
        >
          Log In Again
        </Button>
      </div>
    </>
  );
};

export default Logout;
