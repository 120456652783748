import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser /*, logoutUser*/ } from 'features/users/usersSlice';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  Alert
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';

const LoginSchema = Yup.object().shape({
  email: Yup.string().required('email is required'),
  password: Yup.string().required('Password is required')
});

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.users.error);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (localStorage.getItem('user')) {
      //dispatch(logoutUser());
      navigate('/dashboard/clients');
    }
  }, []);

  return (
    <div className="w-full sm:w-96 p-4 bg-white">
      <div className="mb-6">
        <Typography variant="h3">LogIn</Typography>
      </div>

      {error && (
        <div className="my-3">
          <Alert severity="error">{error}</Alert>
        </div>
      )}

      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={LoginSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(loginUser(values))
            .then(() => {
              navigate('/dashboard/clients');
            })
            .catch((err) => {
              console.log(err);
              toast.error('There was an error in response');
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          isSubmitting,
          errors,
          values,
          handleChange,
          handleBlur,
          touched
        }) => (
          <Form>
            <Field type="hidden" name="plan" className="form-control" />

            <div className="mb-6">
              <TextField
                fullWidth
                error={errors.email && touched.email}
                helperText={errors.email && touched.email && errors.email}
                label="Email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                variant="outlined"
              />
            </div>

            <div className="mb-6">
              <FormControl
                fullWidth
                error={errors.password && touched.password}
                variant="outlined"
              >
                <InputLabel>Password</InputLabel>
                <OutlinedInput
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  variant="outlined"
                />
              </FormControl>
            </div>

            <Button
              color="primary"
              disabled={isSubmitting}
              disableElevation
              type="submit"
              variant="contained"
            >
              {isSubmitting ? 'loading..' : 'Log In'}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
