import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import {
  getClients,
  saveClient,
  deleteClient,
  importClientAffiliates
} from '../../api/clientApi';
import { toast } from 'react-toastify';

const client = new schema.Entity('clients', {}, { idAttribute: '_id' });
const clientsSchema = [client];

export const fetchClients = createAsyncThunk(
  'clients/fetchClients',
  async (params) => await getClients(params)
);

export const addClient = createAsyncThunk(
  'clients/addClient',
  async (client) => await saveClient(client)
);

export const editClient = createAsyncThunk(
  'clients/editClient',
  async (client) => await saveClient(client)
);

export const removeClient = createAsyncThunk(
  'clients/removeClient',
  async (clientId) => await deleteClient(clientId)
);

export const uploadAffiliates = createAsyncThunk(
  'clients/uploadAffiliates',
  async (data) => await importClientAffiliates(data)
);

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    data: { entities: { clients: {} }, result: [] },
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        const normalizedClients = normalize(action.payload, clientsSchema);
        state.data = normalizedClients;
        state.data.result = normalizedClients.result || [];
        state.data.entities.clients = normalizedClients.entities.clients || {};
        state.data.entities.contacts =
          normalizedClients.entities.contacts || {};
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(addClient.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(addClient.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.data.entities.clients[action.payload.id] = action.payload;
        state.data.result.unshift(action.payload.id);
      })
      .addCase(addClient.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(editClient.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(editClient.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.data.entities.clients[action.payload._id] = action.payload;
      })
      .addCase(editClient.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(removeClient.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(removeClient.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.data.result.filter((item) => item !== action.meta.arg);
      })
      .addCase(removeClient.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(
          action.error.message || 'There was an error deleting the client'
        );
      })

      .addCase(uploadAffiliates.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(uploadAffiliates.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        if (state.data.entities.clients[action.meta.arg.clientId]) {
          state.data.entities.clients[action.meta.arg.clientId].affiliates =
            action.payload.saved;
        }
      })
      .addCase(uploadAffiliates.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(
          action.error.message || 'There was an error importing affiliates'
        );
      });
  }
});

export default clientSlice.reducer;
