import axios from './axios';

const baseUrl = '/quote-requests';

export function getQuoteRequests(params = {}) {
  return axios({
    method: 'get',
    url: baseUrl,
    params
  });
}
