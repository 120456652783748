import React from 'react';
import { Button, Typography, Card, CardContent } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Container = ({ content, id, title }) => {
  return (
    <div className="p-3 border mb-6">
      <div className="flex sm:justify-between sm:items-center mb-3">
        <Typography variant="h4">{title}</Typography>
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          to={`update/${title.toLowerCase()}`}
          variant="outlined"
        >
          Update
        </Button>
      </div>

      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

const Details = ({ planDetails }) => {
  return (
    <div className="p-3">
      <Container content={planDetails.faqs} id={planDetails._id} title="FAQs" />
      <Container
        content={planDetails.requirements}
        id={planDetails._id}
        title="Requirements"
      />
      <Container
        content={planDetails.claim}
        id={planDetails._id}
        title="Claim"
      />
      <Container
        content={planDetails.refund}
        id={planDetails._id}
        title="Refund"
      />
      <Container
        content={planDetails.exclusions}
        id={planDetails._id}
        title="Exclusions"
      />
    </div>
  );
};

export default Details;
