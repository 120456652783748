import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPlanDetails,
  editPlanDetails
} from 'features/planDetails/planDetailsSlice';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Header } from './components';
import { Button, TextField, Typography } from '@mui/material';

const planDetailSchema = Yup.object().shape({
  company: Yup.string().required('Required'),
  summary: Yup.string().nullable(),
  requirements: Yup.string().nullable(),
  claim: Yup.string().nullable(),
  refund: Yup.string().nullable(),
  exclusions: Yup.string().nullable()
});

const PlanSummaryManage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { planDetailId } = useParams();
  const planDetailsStatus = useSelector((state) => state.planDetails.status);
  const planDetail = useSelector((state) => {
    if (
      planDetailId &&
      state.planDetails.data.entities.planDetails[planDetailId]
    ) {
      return state.planDetails.data.entities.planDetails[planDetailId];
    }
  });

  useEffect(() => {
    if (planDetailId) {
      if (
        planDetailsStatus === 'idle' ||
        (planDetailsStatus === 'succeeded' && !planDetail)
      ) {
        navigate('/dashboard/plan-details');
      }
    }
  }, [planDetailId, planDetailsStatus]);

  return (
    <>
      <div className="p-3">
        <Header />
      </div>

      <div className="p-3">
        <Formik
          initialValues={{
            company: '',
            summary: '',
            requirements: '',
            claim: '',
            refund: '',
            exclusions: '',
            ...planDetail
          }}
          onSubmit={(values, { setSubmitting }) => {
            // same shape as initial values
            dispatch(
              planDetailId
                ? editPlanDetails({ ...values, planDetailId })
                : addPlanDetails(values)
            )
              .unwrap()
              .then((planDetail) => {
                navigate(`/dashboard/plan-details/${planDetail._id || ''}`);
              })
              .catch((error) => console.log(error))
              .finally(() => {
                setSubmitting(false);
              });
          }}
          validationSchema={planDetailSchema}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            handleBlur,
            handleChange
          }) => (
            <div className="max-w-md">
              <Form>
                <div className="mb-6">
                  <TextField
                    defaultValue={values.company}
                    error={errors.company && touched.company}
                    fullWidth
                    helperText={
                      errors.company && touched.company && errors.company
                    }
                    label="Plan/Company Name"
                    name="company"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                  <div className="py-3">
                    <Typography variant="body2">
                      It's just for reference and will not be shown in frontend.
                    </Typography>
                  </div>
                </div>

                <Button
                  color="primary"
                  disableElevation
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting ? 'Saving' : 'Save'}
                </Button>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PlanSummaryManage;
