import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuoteRequests } from 'features/quoteRequests/quoteRequestsSlice';
import { List, Search } from './components';
import { Alert, Typography } from '@mui/material';

const QuoteRequests = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.quoteRequests.status);
  const result = useSelector((state) => state.quoteRequests.data.result);
  const quoteRequests = useSelector(
    (state) => state.quoteRequests.data.entities.quoteRequests
  );

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchQuoteRequests());
    }
  }, []);

  return (
    <>
      <div className="p-3 lg:flex lg:justify-between lg:items-center">
        <div className="my-2">
          <Typography variant="h1">Quote Requests</Typography>
        </div>
        <Search />
      </div>

      {result && result.length ? (
        <List result={result} quoteRequests={quoteRequests} />
      ) : (
        <>
          {status === 'succeeded' ? (
            <div className="py-3">
              <Alert severity="warning">Found 0 results</Alert>
            </div>
          ) : (
            <>loading...</>
          )}
        </>
      )}
    </>
  );
};

export default QuoteRequests;
