import React, { useState, useEffect } from 'react';
import * as clientApi from '../../../../api/clientApi';
import { parseISO, format } from 'date-fns';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';

const Log = ({ phone }) => {
  const [logs, setLogs] = useState(null);

  useEffect(() => {
    clientApi
      .log(phone)
      .then((data) => {
        setLogs(data);
      })
      .catch((err) => toast.error(err.message || 'Failed to retrieve the log'));
  }, [phone]);

  return (
    <div style={{ marginTop: 30 }}>
      {logs &&
        logs.map((log) => (
          <div className="py-6 border-b" key={log._id}>
            <Typography variant="body2" gutterBottom>
              {format(parseISO(log.createdAt), 'MM/dd/yyyy hh:mm bbb')}
            </Typography>
            <div style={{ whiteSpace: 'pre-wrap' }}>
              <Typography variant="body1">{log.summary}</Typography>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Log;
