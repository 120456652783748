import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';

const Header = () => {
  return (
    <div className="p-3">
      <div className="flex sm:justify-between">
        <Typography component="h1" variant="h2">
          Plans Summaries
        </Typography>

        <Button
          color="primary"
          component={RouterLink}
          to="add"
          variant="contained"
        >
          New Plan Details
        </Button>
      </div>
    </div>
  );
};

export default Header;
