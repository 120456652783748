import axios from './axios';

const baseUrl = '/clients';

export function saveUser(user) {
  return axios({
    method: user._id ? 'put' : 'post',
    url: `${baseUrl}/${user._id || ''}`,
    data: user
  });
}

export function loginUser(data) {
  return axios({
    method: 'post',
    url: `${baseUrl}/login`,
    data
  });
}

/*
export function deleteUser(userId) {
  return fetch(baseUrl + userId, { method: 'DELETE' })
    .then(handleResponse)
    .catch(handleError);
}
*/
