import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Input as InputIcon, Menu as MenuIcon } from '@mui/icons-material';
import jwtDecode from 'jwt-decode';

const TopBar = ({ onOpenNavBarMobile }) => {
  const [user, setUser] = useState({ name: null });

  useEffect(() => {
    const token =
      localStorage.getItem('user') &&
      JSON.parse(localStorage.getItem('user')).token;
    if (token) {
      setUser(jwtDecode(token));
    }
  }, []);

  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        <div className="me-2">
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </div>
        <Link to="/dashboard/clients">IIC Inc.</Link>
      </div>
      <IconButton
        component={RouterLink}
        to="/logout"
        color="inherit"
        onClick={onOpenNavBarMobile}
      >
        <InputIcon />
      </IconButton>
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func.isRequired
};

export default TopBar;
