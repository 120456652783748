import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { removeRates } from 'features/plans/plansSlice';
import { CloudHostingUrl } from 'utilities/config';

const Header = ({ plan }) => {
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <div className="p-3 flex md:justify-between md:items-center">
        <Typography variant="h1">Rates {plan?.name}</Typography>
        <div className="flex">
          <div className="me-2">
            <Button
              color="primary"
              component={RouterLink}
              to="import"
              variant="contained"
            >
              Import Rates
            </Button>
          </div>
          <Button
            color="secondary"
            onClick={() => {
              setDialogOpen(true);
            }}
            variant="contained"
          >
            Delete All
          </Button>
        </div>
      </div>

      <div className="p-3">
        <p>
          <a
            href={`${CloudHostingUrl}/images/partners/${plan?.slug}.png`}
            rel="noopener noreferrer"
            target="_blank"
          >
            Suggested Logo URL
          </a>
        </p>
      </div>

      <Dialog open={dialogOpen}>
        <DialogTitle>Delete Rates?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete all rates?
          </DialogContentText>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              color="secondary"
              variant="outlined"
            >
              No
            </Button>
            <Button
              onClick={() => {
                handleDialogClose();
                dispatch(removeRates(plan));
              }}
              color="primary"
              variant="contained"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Header;
