import axios from './axios';

const baseUrl = '/plan-details/visitor';

export function getPlanMetaVisitorMany(planIds) {
  return axios({
    method: 'post',
    url: baseUrl,
    data: planIds
  });
}

export function importPlanMetaVisitor(input) {
  let data = new FormData();
  data.append('csv', input.csv);

  return axios({
    method: 'post',
    url: `${baseUrl}/import`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  });
}

export function deletePlanMetaVisitor() {
  return axios({
    method: 'delete',
    url: baseUrl
  });
}
