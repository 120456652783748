import axios from './axios';

const baseUrl = '/short-urls';

export function getShortUrls(params = {}) {
  return axios({
    method: 'get',
    url: baseUrl,
    params
  });
}
