import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import {
  getPlanMetaVisitorMany,
  importPlanMetaVisitor,
  deletePlanMetaVisitor
} from 'api/planDetailVisitorApi';
import { toast } from 'react-toastify';

const planMetaVisitors = new schema.Entity(
  'planMetaVisitors',
  {},
  { idAttribute: '_id' }
);
const planMetaVisitorsSchema = [planMetaVisitors];

export const fetchPlanMetaVisitors = createAsyncThunk(
  'planMetaVisitors/fetchPlanMetas',
  async (arg) => await getPlanMetaVisitorMany(arg)
);

export const uploadMetaVisitors = createAsyncThunk(
  'planMetaVisitors/uploadMetaVisitors',
  async (data) => await importPlanMetaVisitor(data)
);

export const removePlanMetaVisitors = createAsyncThunk(
  'planMetaVisitors/removePlanMetaVisitors',
  async () => await deletePlanMetaVisitor()
);

export const plansSlice = createSlice({
  name: 'planMetaVisitors',
  initialState: {
    data: { entities: { plans: {} }, result: [] },
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlanMetaVisitors.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchPlanMetaVisitors.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        const normalizedPlans = normalize(
          action.payload,
          planMetaVisitorsSchema
        );
        state.data = normalizedPlans;
        state.data.result = normalizedPlans.result || [];
        state.data.entities.planMetaVisitors =
          normalizedPlans.entities.planMetaVisitors || {};
      })
      .addCase(fetchPlanMetaVisitors.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(action.error.message || 'There was an error loading plans');
      })

      .addCase(uploadMetaVisitors.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(uploadMetaVisitors.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        const normalizedPlans = normalize(
          action.payload,
          planMetaVisitorsSchema
        );
        state.data = normalizedPlans;
        state.data.result = normalizedPlans.result || [];
        state.data.entities.planMetaVisitors =
          normalizedPlans.entities.planMetaVisitors || {};
      })
      .addCase(uploadMetaVisitors.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(
          action.error.message || 'There was an error importing affiliates'
        );
      })

      .addCase(removePlanMetaVisitors.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(removePlanMetaVisitors.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.data.result = [];
        state.data.entities.planMetaVisitors = {};
      })
      .addCase(removePlanMetaVisitors.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(
          action.error.message || 'There was an error deleting records'
        );
      });
  }
});

export default plansSlice.reducer;
