import React from 'react';
import { Grid, Typography } from '@mui/material';

const Header = ({ plan }) => {
  return (
    <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
      <Grid item>
        <Typography component="h1" variant="h2">
          {plan && plan._id ? 'Update ' : 'Add '} Plan
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Header;
