import React, { useEffect } from 'react';
import { Header, Multi, Single } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  fetchRatesSingleTravel,
  fetchRatesMultiTravel
} from 'features/plansTravel/plansTravelSlice';
import Spinner from '../../components/Spinner';
import { Alert } from '@mui/material';

const RatesTravel = () => {
  const navigate = useNavigate();
  const { planId, rateType } = useParams();
  const dispatch = useDispatch();
  const planTravelStatus = useSelector((state) => state.plansTravel.status);
  const plan = useSelector((state) => {
    if (state.plansTravel.data.entities.plansTravel[planId]) {
      return state.plansTravel.data.entities.plansTravel[planId];
    }
  });
  const ratesStatus = useSelector(() => {
    if (rateType === 'single' && plan) {
      return plan.statusSingle;
    } else if (rateType === 'multi' && plan) {
      return plan.statusMulti;
    }
  });
  const result = useSelector((state) => {
    if (rateType === 'single' && plan?.statusSingle === 'succeeded') {
      return plan.ratesSingle;
    } else if (rateType === 'multi' && plan?.statusMulti === 'succeeded') {
      return plan.ratesMulti;
    }
  });
  const rates = useSelector((state) => {
    if (rateType === 'single') {
      return state.plansTravel.data.entities.ratesSingle;
    } else if (rateType === 'multi') {
      return state.plansTravel.data.entities.ratesMulti;
    }
  });

  // Load Plans if not loaded
  useEffect(() => {
    // check if we have plans
    if (planTravelStatus === 'idle' || !plan) {
      navigate('/dashboard/plans-travel');
    }
  }, [planTravelStatus]);

  useEffect(() => {
    if (ratesStatus === 'idle') {
      dispatch(
        rateType === 'single'
          ? fetchRatesSingleTravel(planId)
          : fetchRatesMultiTravel(planId)
      );
    }
  }, [ratesStatus]);

  return (
    <>
      <div className="p-3">
        <Header plan={plan} rateType={rateType} />
      </div>

      <div className="p-3">
        {ratesStatus === 'loading' && <Spinner />}
        {ratesStatus === 'succeeded' && result && result.length ? (
          <>
            <p className="mb-6 text-gray-600">{result.length} records found.</p>
            {rateType === 'multi' ? (
              <Multi rates={rates} result={result} />
            ) : (
              <Single rates={rates} result={result} />
            )}
          </>
        ) : (
          <div className="my-3">
            <Alert severity="warning">no records</Alert>
          </div>
        )}
      </div>
    </>
  );
};

export default RatesTravel;
