import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from 'react-router-dom';
import { history, Router } from './history';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import theme from './theme';
import { Provider } from 'react-redux';
import { Dashboard, Auth } from './layouts';
import {
  Home,
  Login,
  Logout,
  Clients,
  ClientAddUpdate,
  ClientView,
  ClientAffiliateImport,
  PlansVisitor,
  PlanVisitorAdd,
  PlanVisitorUpdate,
  PlanSummaries,
  PlanSummaryView,
  PlanSummaryManage,
  PlanSummaryFieldUpdate,
  PlansDetails,
  PlansDetailsImport,
  Rates,
  RateImport,
  PlansTravel,
  PlanTravelAdd,
  PlanTravelUpdate,
  RatesTravel,
  RatesTravelImport,
  Text,
  QuoteRequests,
  ShortUrls,
  Unauthorized,
  PageNotFound
} from './views';

import store from './app/store';

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <Router history={history}>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />}>
              <Route exact path="home" element={<Home />} />
              <Route path="short-urls" element={<ShortUrls />} />
              <Route path="clients">
                <Route index element={<Clients />} />
                <Route path="add" element={<ClientAddUpdate />} />
                <Route path=":clientId">
                  <Route index element={<ClientView />} />
                  <Route path="update" element={<ClientAddUpdate />} />
                  <Route
                    path="import-affiliates"
                    element={<ClientAffiliateImport />}
                  />
                </Route>
              </Route>
              <Route path="quote-requests">
                <Route index element={<QuoteRequests />} />
                <Route path=":pageNumber" element={<QuoteRequests />} />
              </Route>
              <Route path="plans-visitor">
                <Route index element={<PlansVisitor />} />
                <Route path="add" element={<PlanVisitorAdd />} />
                <Route path=":planId">
                  <Route path="update" element={<PlanVisitorUpdate />} />
                </Route>
              </Route>
              <Route path="rates/:planId">
                <Route index element={<Rates />} />
                <Route path="import" element={<RateImport />} />
                <Route path=":pageNumber" element={<Rates />} />
              </Route>
              <Route path="plan-summary">
                <Route index element={<PlanSummaries />} />
                <Route path="add" element={<PlanSummaryManage />} />
                <Route path=":planDetailId">
                  <Route index element={<PlanSummaryView />} />
                  <Route path="update" element={<PlanSummaryManage />} />
                  <Route
                    path="update/:field"
                    element={<PlanSummaryFieldUpdate />}
                  />
                </Route>
              </Route>
              <Route path="plan-details">
                <Route path=":type">
                  <Route index element={<PlansDetails />} />
                  <Route path="import" element={<PlansDetailsImport />} />
                </Route>
              </Route>
              <Route path="plans-travel">
                <Route index element={<PlansTravel />} />
                <Route path="add" element={<PlanTravelAdd />} />
                <Route path=":planTravelId">
                  <Route path="update" element={<PlanTravelUpdate />} />
                </Route>
              </Route>
              <Route path="rates-travel/:planId/:rateType">
                <Route index element={<RatesTravel />} />
                <Route path="import" element={<RatesTravelImport />} />
              </Route>
              <Route path="text" element={<Text />} />
            </Route>
            <Route path="/" element={<Auth />}>
              <Route index element={<Login />} />
              <Route path="login" element={<Login />} />
              <Route path="logout" element={<Logout />} />
              <Route path="unauthorized" element={<Unauthorized />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>

            {/*
            no longer required; as everything is done through excel files now;
            <Route component={RateCreate} path="/rate/add/:planId" />
            <Route component={RateUpdate} path="/rate/update/:id" />
            */}
          </Routes>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
