import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, List } from './components';
import Spinner from '../../components/Spinner/Spinner';
import { fetchClients } from 'features/clients/clientsSlice';
import { Alert } from '@mui/material';

const Clients = () => {
  const dispatch = useDispatch();
  const clientStatus = useSelector((state) => state.clients.status);
  const result = useSelector((state) => state.clients.data.result);
  const clients = useSelector((state) => state.clients.data.entities.clients);

  useEffect(() => {
    if (clientStatus === 'idle') {
      dispatch(fetchClients());
    }
  }, [clientStatus]);

  return (
    <>
      <Header />
      {clientStatus === 'loading' && <Spinner />}

      {result && result.length ? (
        <List clients={clients} list={result} />
      ) : (
        <div className="p-3">
          <Alert severity="warning">0 clients</Alert>
        </div>
      )}
    </>
  );
};

export default Clients;
