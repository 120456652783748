import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Link,
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';

const List = ({ planDetails, result }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div className="p-3">
      {result && result.length ? (
        <>
          <Typography color="textSecondary" gutterBottom variant="body2">
            {result.length} records found.
          </Typography>

          <PerfectScrollbar>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Company Name</TableCell>
                    <TableCell>Plan Details ID</TableCell>
                  </TableRow>
                </TableHead>
                <tbody>
                  {result.map((key) => (
                    <TableRow key={key}>
                      <TableCell>
                        <Link component={RouterLink} to={`${key}`}>
                          {planDetails[key].company}
                        </Link>
                      </TableCell>

                      <TableCell>
                        <Tooltip title="click to copy">
                          <Button
                            color="primary"
                            size="small"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                planDetails[key]._id
                              );
                            }}
                          >
                            {planDetails[key]._id}
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </div>
          </PerfectScrollbar>
        </>
      ) : (
        <div>
          0 plan Details.{' '}
          <Link to="/dashboard/plan-detail/add">add new plan details</Link>
        </div>
      )}
    </div>
  );
};

List.propTypes = {
  planDetails: PropTypes.object.isRequired
};

export default List;
