import palette from '../palette';
// import typography from '../typography';

export default {
  styleOverrides: {
    root: {
      // ...typography.body2,
      borderBottom: `1px solid ${palette.divider}`
    },
    head: {
      borderBottomWidth: '2px'
    }
  }
};
