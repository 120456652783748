import axios from './axios';

const baseUrl = '/plans-travel';

export function getPlansTravel() {
  return axios({
    method: 'get',
    url: baseUrl
  });
}

export function savePlanTravel({ planTravelId, ...rest }) {
  return axios({
    method: planTravelId ? 'put' : 'post',
    url: planTravelId ? `${baseUrl}/${planTravelId}` : baseUrl,
    data: { ...rest, _id: planTravelId }
  });
}

export function planTravelStatus({ planId, status }) {
  return axios({
    method: 'get',
    url: `${baseUrl}/${planId}/status?isActive=${status}`
  });
}
/*

import { handleResponse, handleError, authHeader } from './apiUtils';
const baseUrl = process.env.REACT_APP_API_URL + '/plans-travel';

export function getPlansTravel() {
  return fetch(baseUrl, {
    headers: { ...authHeader() }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function savePlanTravel(plan) {
  return fetch(baseUrl + '/' + (plan._id || ''), {
    method: plan._id ? 'PUT' : 'POST', // POST for create, PUT to update when id already exists.
    headers: { 'content-type': 'application/json', ...authHeader() },
    body: JSON.stringify(plan)
  })
    .then(handleResponse)
    .catch(handleError);
}

export function planTravelStatus(planId, status) {
  return fetch(`${baseUrl}/${planId}/status?isActive=${status}`, {
    method: 'GET',
    headers: { ...authHeader() }
  })
    .then(handleResponse)
    .catch(handleError);
}
*/
