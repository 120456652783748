import React from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

const Multi = ({ rates, result }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Duration</TableCell>
          <TableCell>Age</TableCell>
          <TableCell>Rate Single</TableCell>
          <TableCell>Rate Family</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {result.map((idx) => (
          <TableRow key={idx}>
            <TableCell>{rates[idx]?.duration}</TableCell>
            <TableCell>
              {rates[idx]?.ageMin}-{rates[idx]?.ageMax}
            </TableCell>
            <TableCell>{rates[idx]?.rateSingle}</TableCell>
            <TableCell>{rates[idx]?.rateFamily}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Multi;
