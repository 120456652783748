import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { PlanTypesSlugMap } from '../../utilities/config';
import { Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { uploadRates } from '../../features/plans/plansSlice';

const ImportSchema = Yup.object().shape({
  planId: Yup.string().required('Plan Id is required')
});

const RateImport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { planId } = useParams();
  const planStatus = useSelector((state) => state.plans.status);
  const plan = useSelector((state) => state.plans.data.entities.plans[planId]);

  // Load Plans if not loaded
  useEffect(() => {
    // check if we have plans
    if (planStatus === 'idle' || !plan) {
      navigate('/dashboard/plans');
    }
  }, [planStatus]);

  return (
    <>
      <div className="p-3">
        <Typography variant="h1">Import rates - {plan?.name}</Typography>
      </div>
      <div className="p-3">
        <p className="text-gray-600 my-3">Use CSV file for bulk imports.</p>

        <Formik
          initialValues={{
            planId,
            planTypeSlug: PlanTypesSlugMap[plan?.planType] || '',
            csv: ''
          }}
          validationSchema={ImportSchema}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(uploadRates(values))
              .unwrap()
              .then(() => {
                navigate(`/dashboard/rates/${planId}`);
              })
              .catch((err) => console.error(err))
              .finally(() => setSubmitting(false));
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className="mb-6">
                <label htmlFor="exampleFormControlFile1">CSV File</label>
                <input
                  type="file"
                  name="csv"
                  className="form-control-file"
                  onChange={(e) => {
                    setFieldValue('csv', e.currentTarget.files[0]);
                  }}
                />
              </div>
              <Button
                color="primary"
                disabled={isSubmitting}
                disableElevation
                type="submit"
                variant="contained"
              >
                {isSubmitting ? 'importing..' : 'Import'}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

/*function mapStateToProps(state, ownProps) {
  const planId = ownProps.match.params.planId;
  const plan =
    state.plansReducer.entities.plans &&
    state.plansReducer.entities.plans[planId];

  if (typeof plan === 'undefined') {
    ownProps.history.push('/plans');
  }

  return {
    plan,
    plans: state.plansReducer
  };
}

const mapDispatchToProps = {
  importRates: rateActions.importRates
};*/

export default RateImport;
