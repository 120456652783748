import React, { useState } from 'react';
import { Link, TextField } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Form = ({
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  values
}) => {
  const [editorState, setEditorState] = useState(null);

  useState(() => {
    if (values.summary) {
      const { contentBlocks, entityMap } = htmlToDraft(values.summary);

      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlocks, entityMap)
        )
      );
    }
  }, [values.summary]);

  return (
    <>
      <div className="mb-6">
        <TextField
          defaultValue={values.minCost}
          error={errors.minCost && touched.minCost}
          fullWidth
          helperText={errors.minCost && touched.minCost && errors.minCost}
          label="Min Cost"
          name="minCost"
          onBlur={handleBlur}
          onChange={handleChange}
          type="number"
          variant="outlined"
        />
      </div>

      <div className="mb-6">
        <TextField
          defaultValue={values.planDetailsId}
          error={errors.planDetailsId && touched.planDetailsId}
          fullWidth
          helperText={
            errors.planDetailsId &&
            touched.planDetailsId &&
            errors.planDetailsId
          }
          label="Plan Summary Id"
          name="planDetailsId"
          onBlur={handleBlur}
          onChange={handleChange}
          variant="outlined"
        />
        <small className="form-text text-muted">
          You will find it in{' '}
          <Link component={RouterLink} to="/dashboard/plan-details">
            Plan Summary
          </Link>{' '}
          section.
        </small>
      </div>

      <div className="mb-6">
        <Editor
          editorClassName="draft-editor"
          editorState={editorState}
          onBlur={() =>
            setFieldValue(
              'summary',
              draftToHtml(convertToRaw(editorState.getCurrentContent()))
            )
          }
          onEditorStateChange={setEditorState}
          placeholder="The message goes here..."
          wrapperClassName="rich-editor plan-travel-editor-wrapper"
        />
      </div>
    </>
  );
};

export default Form;
