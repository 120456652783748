import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, List } from './components';
import { fetchPlansTravel } from 'features/plansTravel/plansTravelSlice';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner/Spinner';
import { Dashboard } from '../../layouts';
import { Alert } from '@mui/material';

const PlansTravel = () => {
  const dispatch = useDispatch();
  const planTravelStatus = useSelector((state) => state.plansTravel.status);
  const result = useSelector((state) => state.plansTravel.data.result);
  const plansTravel = useSelector(
    (state) => state.plansTravel.data.entities.plansTravel
  );

  useEffect(() => {
    if (planTravelStatus === 'idle') {
      dispatch(fetchPlansTravel());
    }
  }, [planTravelStatus]);

  return (
    <>
      <Header />
      <div className="p-3">
        {planTravelStatus === 'loading' && <Spinner />}
        {result && result.length ? (
          <List result={result} plans={plansTravel} />
        ) : (
          <div className="my-3">
            <Alert severity="warning">0 records</Alert>
          </div>
        )}
      </div>
    </>
  );
};

export default PlansTravel;
