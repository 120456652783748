import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useNavigate, useParams } from 'react-router-dom';

const Student = ({ rates, plan }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  useEffect(() => {
    setPage(parseInt(params.page) || 0);
  }, [params.page]);

  const handlePageChange = (event, page) => {
    navigate(`/dashboard/rates/${plan._id}/${page}`);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    navigate(`/dashboard/rates/${plan._id}`);
  };

  return (
    <>
      <div className="mb-6">
        <TablePagination
          component="div"
          count={plan.rates.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 50, 100, 200, 500]}
        />
      </div>

      <PerfectScrollbar>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Coverage</TableCell>
                <TableCell>Age Range</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>max Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plan.rates
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((id) => (
                  <TableRow key={id}>
                    <TableCell>{rates[id].coverage}</TableCell>
                    <TableCell>
                      {rates[id].ageMin} - {rates[id].ageMax}
                    </TableCell>
                    <TableCell>{rates[id].rate}</TableCell>
                    <TableCell>{rates[id].maxCost}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>

      <TablePagination
        component="div"
        count={plan.rates.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 50, 100, 200, 500]}
      />
    </>
  );
};

export default Student;
