import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeClient } from 'features/clients/clientsSlice';

const Header = ({ client }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <div className="p-3 mb-3 flex justify-between">
      <Typography component="h1" variant="h2">
        {client.name}
      </Typography>

      <div>
        <Button
          color="primary"
          component={RouterLink}
          to="update"
          variant="contained"
        >
          Update
        </Button>{' '}
        <Button
          color="primary"
          onClick={() => {
            setDialogOpen(true);
          }}
          variant="outlined"
        >
          Delete
        </Button>
      </div>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Delete Client?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this client?
          </DialogContentText>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              color="secondary"
              variant="outlined"
            >
              No
            </Button>
            <Button
              onClick={() => {
                handleDialogClose();
                // dispatch always return a wrapped promise; we must unwrap to figure out if its rejected or resolved
                dispatch(removeClient(client._id))
                  .unwrap()
                  .then((response) => {
                    navigate('/dashboard/clients');
                  });
              }}
              color="primary"
              variant="contained"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Header;
