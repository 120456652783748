import React from 'react';
import {
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { Alert } from '@mui/material';
import { usePlans } from 'hooks';
import Spinner from 'components/Spinner';

const Form = ({
  clientId,
  errors,
  touched,
  handleBlur,
  handleChange,
  values,
  setFieldValue
}) => {
  const {
    status: planStatus,
    error: planError,
    plans,
    result: planResult
  } = usePlans();

  return (
    <>
      <div className="mt-10 mb-6">
        <Typography variant="h4">Account Details</Typography>
      </div>
      <div className="mb-4">
        <TextField
          defaultValue={values.name}
          error={errors.name && touched.name}
          fullWidth
          helperText={errors.name && touched.name && errors.name}
          label="Name"
          name="name"
          onBlur={handleBlur}
          onChange={handleChange}
          variant="outlined"
        />
      </div>

      <div className="mb-4">
        <TextField
          defaultValue={values.email}
          error={errors.email && touched.email}
          fullWidth
          helperText={errors.email && touched.email && errors.email}
          label="Email"
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          variant="outlined"
        />
      </div>

      <div className="mb-4">
        <TextField
          defaultValue={values.cellNo}
          error={errors.cellNo && touched.cellNo}
          fullWidth
          helperText={errors.cellNo && touched.cellNo && errors.cellNo}
          label="Cell#"
          name="cellNo"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          variant="outlined"
        />
      </div>

      <div className="mb-4">
        <TextField
          defaultValue={values.domain}
          error={errors.domain && touched.domain}
          fullWidth
          helperText={errors.domain && touched.domain && errors.domain}
          label="Domain"
          name="domain"
          onBlur={handleBlur}
          onChange={handleChange}
          variant="outlined"
        />
      </div>

      {!clientId && (
        <div className="mb-4">
          <TextField
            defaultValue={values.password}
            error={errors.password && touched.password}
            fullWidth
            helperText={errors.password && touched.password && errors.password}
            label="Password"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            variant="outlined"
          />
        </div>
      )}

      <div className="mt-10 mb-6">
        <Typography variant="h4">Twilio</Typography>
      </div>
      <div className="mb-4">
        <TextField
          defaultValue={values?.twilio?.accountId}
          error={errors.twilio?.accountId && touched?.twilio?.accountId}
          fullWidth
          helperText={
            errors?.twilio?.accountId &&
            touched?.twilio?.accountId &&
            errors?.twilio?.accountId
          }
          label="Account ID"
          name="twilio.accountId"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          variant="outlined"
        />
      </div>

      <div className="mb-4">
        <TextField
          defaultValue={values?.twilio?.token}
          error={errors.twilio?.token && touched?.twilio?.token}
          fullWidth
          helperText={
            errors?.twilio?.token &&
            touched?.twilio?.token &&
            errors?.twilio?.token
          }
          label="Token"
          name="twilio.token"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          variant="outlined"
        />
      </div>

      <div className="mb-4">
        <TextField
          defaultValue={values?.twilio?.phone}
          error={errors.twilio?.phone && touched?.twilio?.phone}
          fullWidth
          helperText={
            errors?.twilio?.phone &&
            touched?.twilio?.phone &&
            errors?.twilio?.phone
          }
          label="Twilio Phone#"
          name="twilio.phone"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          variant="outlined"
        />
      </div>

      <div className="mt-10 mb-6">
        <Typography variant="h4">Options</Typography>
      </div>
      <div className="mb-4">
        <TextField
          defaultValue={values?.options?.deductible}
          error={errors.options?.deductible && touched?.options?.deductible}
          fullWidth
          helperText={
            errors?.options?.deductible &&
            touched?.options?.deductible &&
            errors?.options?.deductible
          }
          label="Default Deductible"
          name="options.deductible"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          variant="outlined"
        />
      </div>

      <div className="mb-4">
        <TextField
          defaultValue={values?.options?.coverageSuper}
          error={
            errors.options?.coverageSuper && touched?.options?.coverageSuper
          }
          fullWidth
          helperText={
            errors?.options?.coverageSuper &&
            touched?.options?.coverageSuper &&
            errors?.options?.coverageSuper
          }
          label="Def. Coverage Super"
          name="options.coverageSuper"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          variant="outlined"
        />
      </div>

      <div className="mb-4">
        <TextField
          defaultValue={values?.options?.coverageVisitor}
          error={
            errors.options?.coverageVisitor && touched?.options?.coverageVisitor
          }
          fullWidth
          helperText={
            errors?.options?.coverageVisitor &&
            touched?.options?.coverageVisitor &&
            errors?.options?.coverageVisitor
          }
          label="Def. Coverage Visitor"
          name="options.coverageVisitor"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          variant="outlined"
        />
      </div>

      <div className="mb-4">
        {planStatus === 'loading' && <Spinner />}
        {planError && (
          <div className="my-3">
            <Alert severity="warning">{planError}</Alert>
          </div>
        )}
        {planResult && planResult.length && (
          <>
            <div className="mb-2">
              <Typography variant="h5">Select Plans to show</Typography>
            </div>
            <FormGroup>
              {planResult
                .filter((key) => plans[key].isActive === true)
                .map((key, idx) => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        name={`options.plans[${idx}]`}
                        value={key}
                        checked={values.options.plans.includes(key)}
                        onChange={(e) => {
                          if (values.options.plans.includes(key)) {
                            setFieldValue(
                              'options.plans',
                              values.options.plans.filter(
                                (item) => item !== key
                              )
                            );
                          } else {
                            setFieldValue('options.plans', [
                              ...values.options.plans,
                              key
                            ]);
                          }
                        }}
                      />
                    }
                    label={plans[key].name}
                  />
                ))}
            </FormGroup>
          </>
        )}
      </div>

      <div className="mt-10 mb-6">
        <Typography variant="h4" gutterBottom style={{ marginTop: '2rem' }}>
          Misc.
        </Typography>
      </div>
      <div className="mb-4">
        <TextField
          defaultValue={values.quoteMsg}
          error={errors.quoteMsg && touched.quoteMsg}
          multiline={true}
          minRows={5}
          fullWidth
          helperText={
            (errors.quoteMsg && touched.quoteMsg && errors.quoteMsg) ||
            'Enter {name} and {quoteUrl} as placeholders for the Name and Quote URLs. For Example: Dear {name} click the url {quoteUrl} for your quote.'
          }
          label="Quote Message"
          name="quoteMsg"
          onBlur={handleBlur}
          onChange={handleChange}
          variant="outlined"
        />
      </div>
    </>
  );
};

export default Form;
