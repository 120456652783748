/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import Receipt from '@mui/icons-material/LibraryBooks';
import BusinessCenter from '@mui/icons-material/BusinessCenter';
import ListAlt from '@mui/icons-material/ListAlt';
import Group from '@mui/icons-material/Group';
import Chat from '@mui/icons-material/Chat';

export default [
  {
    title: 'Pages',
    pages: [
      {
        title: 'Client',
        href: '/clients',
        icon: Group
      },
      {
        title: 'Quote Requests',
        href: '/quote-requests/0',
        icon: Chat
      },
      {
        title: 'Plans',
        href: '/plans',
        icon: BusinessCenter
      },
      {
        title: 'Plan Summary',
        href: '/plan-details',
        icon: Receipt
      },
      {
        title: 'VTC Plan Details',
        href: '/plan-meta/visitor',
        icon: ListAlt
      },
      {
        title: 'Int. Stu. Plan Details',
        href: '/plan-meta/student',
        icon: ListAlt
      },
      {
        title: 'Plans Travel',
        href: '/plans-travel',
        icon: BusinessCenter
      }
    ]
  }
];
