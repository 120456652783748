import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { removeRatesTravel } from 'features/plansTravel/plansTravelSlice';
import { CloudHostingUrl } from 'utilities/config';

const Header = ({ plan, rateType }) => {
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <div className="lg:flex lg:justify-between lg:items-center">
        <div className="py-3">
          <Typography component="h1" variant="h2">
            Travel Rates {rateType} trip - {plan?.name}
          </Typography>
        </div>
        <div>
          <Button
            color="primary"
            component={RouterLink}
            to={'import'}
            variant="contained"
          >
            Import Rates
          </Button>
          <Button
            style={{ marginLeft: '1rem' }}
            color="secondary"
            onClick={() => {
              setDialogOpen(true);
            }}
            variant="contained"
          >
            Delete All
          </Button>
        </div>
      </div>

      <p>
        <a
          href={`${CloudHostingUrl}/images/partners/${plan?.slug}.png`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Suggested Logo URL
        </a>
      </p>

      <Dialog open={dialogOpen}>
        <DialogTitle>Delete Rates?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete all rates?
          </DialogContentText>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              color="secondary"
              variant="outlined"
            >
              No
            </Button>
            <Button
              onClick={() => {
                handleDialogClose();
                dispatch(removeRatesTravel({ planId: plan?._id, rateType }));
              }}
              color="primary"
              variant="contained"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Header;
