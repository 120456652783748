import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Form as PlanForm } from './components';
import { Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { editPlan } from 'features/plans/plansSlice';

const PlansSchema = Yup.object().shape({
  coupleDiscount: Yup.number()
    .min(0)
    .lessThan(100)
    .required('Couple Discount is required'),
  coupleFactor: Yup.number()
    .moreThan(0)
    .max(1)
    .required('Couple factor is required'),
  superCoupleMonthlyAdjustment: Yup.number().required(
    'Super Couple Monthly Adjustment is required'
  ),
  maxCost: Yup.number()
    .moreThan(0)
    .required('maxCost is required. For visitors enter any random number.'),
  deductibleType: Yup.string().required(
    'Select what type of deductible is offered'
  ),
  haveMonthlyPlansVisitor: Yup.boolean().required,
  monthlyPlansVisitorMinDays: Yup.number().when('haveMonthlyPlansVisitor', {
    is: 'true',
    then: Yup.number().min(1).required('Required')
  }),
  monthlyPlansVisitorMinAmount: Yup.number().when('haveMonthlyPlansVisitor', {
    is: 'true',
    then: Yup.number().min(1).required('Required')
  }),
  monthlyPlansVisitorFactor: Yup.number().when('haveMonthlyPlansVisitor', {
    is: 'true',
    then: Yup.number().min(1).required('Required')
  }),
  haveMonthlyPlans: Yup.boolean().required(
    'Select if monthly plans are available or not'
  ),
  ageCalculation: Yup.string().required(
    'Please select how the Age will be calculated'
  )
});

const PlanVisitorUpdate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { planId } = useParams();
  const planStatus = useSelector((state) => state.plans.status);
  const plan = useSelector((state) => state.plans.data.entities.plans[planId]);

  useEffect(() => {
    if (planStatus === 'idle' || (planStatus === 'succeeded' && !plan)) {
      navigate('/dashboard/plans-visitor');
    }
  }, [planStatus]);

  return (
    <>
      <div className="p-3">
        <Typography variant="h1">Update {plan?.name}</Typography>
      </div>

      <div className="p-3 max-w-md">
        {plan && (
          <Formik
            initialValues={{
              _id: plan._id,
              coupleDiscount:
                plan && plan.coupleFactor
                  ? (100 - plan.coupleFactor * 100).toFixed(2)
                  : 0,
              coupleFactor: plan.coupleFactor,
              superCoupleMonthlyAdjustment: plan.superCoupleMonthlyAdjustment
                ? plan.superCoupleMonthlyAdjustment
                : 0,
              haveMonthlyPlansVisitor: plan.haveMonthlyPlansVisitor ? 1 : 0,
              monthlyPlansVisitorMinDays: plan.monthlyPlansVisitorMinDays || '',
              monthlyPlansVisitorMinAmount:
                plan.monthlyPlansVisitorMinAmount || '',
              monthlyPlansVisitorFactor: plan.monthlyPlansVisitorFactor || 1,
              haveMonthlyPlans: plan.haveMonthlyPlans,
              deductibleType: plan.deductibleType,
              ageCalculation: plan.ageCalculation,
              summary: plan.summary,
              minCost: plan.minCost,
              maxCost: plan.maxCost,
              planDetailsId: plan.planDetailsId,
              buyDirectUrl: plan.buyDirectUrl,
              claimFormUrl: plan.claimFormUrl,
              policyWordingUrl: plan.policyWordingUrl
            }}
            validationSchema={PlansSchema}
            onSubmit={(
              {
                haveMonthlyPlansVisitor,
                monthlyPlansVisitorMinDays,
                monthlyPlansVisitorMinAmount,
                monthlyPlansVisitorFactor,
                ...rest
              },
              { setSubmitting }
            ) => {
              dispatch(
                editPlan({
                  ...rest,
                  ...(Number(haveMonthlyPlansVisitor)
                    ? { monthlyPlansVisitorMinDays }
                    : {}),
                  ...(Number(haveMonthlyPlansVisitor)
                    ? { monthlyPlansVisitorMinAmount }
                    : {}),
                  ...(Number(haveMonthlyPlansVisitor)
                    ? { monthlyPlansVisitorFactor }
                    : {}),
                  haveMonthlyPlansVisitor: !!Number(haveMonthlyPlansVisitor),
                  planId
                })
              )
                .unwrap()
                .then(() => {
                  navigate('/dashboard/plans-visitor');
                })
                .catch((err) => console.error(err))
                .finally(() => setSubmitting(false));
            }}
          >
            {({
              errors,
              touched,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              values
            }) => (
              <Form>
                <PlanForm
                  {...{
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    values
                  }}
                />

                <Button
                  color="primary"
                  disableElevation
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting ? 'Updating' : 'Update'}
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default PlanVisitorUpdate;
