import React, { useEffect, useState } from 'react';
import { InputAdornment, Link, TextField } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Form = ({
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  values
}) => {
  const [editorState, setEditorState] = useState(null);

  useEffect(() => {
    if (values.summary) {
      const { contentBlocks, entityMap } = htmlToDraft(values.summary);

      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlocks, entityMap)
        )
      );
    }
  }, [values.summary]);

  return (
    <>
      <div className="mb-6 w-32">
        <TextField
          defaultValue={values.coupleDiscount}
          error={errors.coupleDiscount && touched.coupleDiscount}
          fullWidth
          helperText={
            errors.coupleDiscount &&
            touched.coupleDiscount &&
            errors.coupleDiscount
          }
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
          label="Couple Discount"
          name="coupleDiscount"
          onBlur={handleBlur}
          onChange={(e) => {
            const coupleDiscount = e.target.value
              ? parseFloat(e.target.value)
              : 0;
            const coupleFactor = (100 - coupleDiscount) / 100;
            setFieldValue('coupleFactor', coupleFactor);
            handleChange(e);
          }}
          variant="outlined"
        />
      </div>

      <div className="mb-6 w-64">
        <TextField
          SelectProps={{ native: true }}
          defaultValue={values.haveMonthlyPlansVisitor}
          error={
            errors.haveMonthlyPlansVisitor && touched.haveMonthlyPlansVisitor
          }
          fullWidth
          helperText={
            errors.haveMonthlyPlansVisitor &&
            touched.haveMonthlyPlansVisitor &&
            errors.haveMonthlyPlansVisitor
          }
          label="VisitorVisa Monthly Options?"
          name="haveMonthlyPlansVisitor"
          onBlur={handleBlur}
          onChange={handleChange}
          select
          variant="outlined"
        >
          <option value=""></option>
          <option value="1">Yes</option>
          <option value="0">No</option>
        </TextField>
      </div>

      {!!Number(values.haveMonthlyPlansVisitor) && (
        <div className="mb-8">
          <div className="grid grid-cols-3 gap-2">
            <div className="mb-6">
              <TextField
                defaultValue={values.monthlyPlansVisitorMinDays}
                error={
                  errors.monthlyPlansVisitorMinDays &&
                  touched.monthlyPlansVisitorMinDays
                }
                fullWidth
                helperText={
                  errors.monthlyPlansVisitorMinDays &&
                  touched.monthlyPlansVisitorMinDays &&
                  errors.monthlyPlansVisitorMinDays
                }
                label="Min. days"
                name="monthlyPlansVisitorMinDays"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
              />
            </div>

            <div className="mb-6">
              <TextField
                defaultValue={values.monthlyPlansVisitorMinAmount}
                error={
                  errors.monthlyPlansVisitorMinAmount &&
                  touched.monthlyPlansVisitorMinAmount
                }
                fullWidth
                helperText={
                  errors.monthlyPlansVisitorMinAmount &&
                  touched.monthlyPlansVisitorMinAmount &&
                  errors.monthlyPlansVisitorMinAmount
                }
                label="Min. amount"
                name="monthlyPlansVisitorMinAmount"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
              />
            </div>

            <div className="mb-6">
              <TextField
                defaultValue={values.monthlyPlansVisitorFactor}
                error={
                  errors.monthlyPlansVisitorFactor &&
                  touched.monthlyPlansVisitorFactor
                }
                fullWidth
                helperText={
                  errors.monthlyPlansVisitorFactor &&
                  touched.monthlyPlansVisitorFactor &&
                  errors.monthlyPlansVisitorFactor
                }
                label="Factor"
                name="monthlyPlansVisitorFactor"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
              />
            </div>
          </div>
        </div>
      )}

      <div className="mb-6  w-64">
        <TextField
          SelectProps={{ native: true }}
          defaultValue={values.haveMonthlyPlans}
          error={errors.haveMonthlyPlans && touched.haveMonthlyPlans}
          fullWidth
          helperText={
            errors.haveMonthlyPlans &&
            touched.haveMonthlyPlans &&
            errors.haveMonthlyPlans
          }
          label="SuperVisa Monthly Options?"
          name="haveMonthlyPlans"
          onBlur={handleBlur}
          onChange={handleChange}
          select
          variant="outlined"
        >
          <option value=""></option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </TextField>
      </div>

      <div className="mb-6 w-48">
        <TextField
          SelectProps={{ native: true }}
          defaultValue={values.deductibleType}
          error={errors.deductibleType && touched.deductibleType}
          fullWidth
          helperText={
            errors.deductibleType &&
            touched.deductibleType &&
            errors.deductibleType
          }
          label="Deductible Type"
          name="deductibleType"
          onBlur={handleBlur}
          onChange={handleChange}
          select
          variant="outlined"
        >
          <option value=""></option>
          <option value="0">claim</option>
          <option value="1">policy</option>
        </TextField>
      </div>

      <div className="mb-6  w-48">
        <TextField
          SelectProps={{ native: true }}
          defaultValue={values.ageCalculation}
          error={errors.ageCalculation && touched.ageCalculation}
          fullWidth
          helperText={
            errors.ageCalculation &&
            touched.ageCalculation &&
            errors.ageCalculation
          }
          label="Age calculation"
          name="ageCalculation"
          onBlur={handleBlur}
          onChange={handleChange}
          select
          variant="outlined"
        >
          <option value=""></option>
          <option value="0">Effective Date</option>
          <option value="1">Application Date</option>
        </TextField>
      </div>

      <div className="mb-6 w-48">
        <TextField
          defaultValue={values.superCoupleMonthlyAdjustment}
          error={
            errors.superCoupleMonthlyAdjustment &&
            touched.superCoupleMonthlyAdjustment
          }
          fullWidth
          helperText={
            errors.superCoupleMonthlyAdjustment &&
            touched.superCoupleMonthlyAdjustment &&
            errors.superCoupleMonthlyAdjustment
          }
          label="Super Couple Monthly Adj"
          name="superCoupleMonthlyAdjustment"
          onBlur={handleBlur}
          onChange={handleChange}
          variant="outlined"
        />
      </div>

      <div className="mb-6">
        <div className="grid grid-cols-2 gap-4">
          <TextField
            defaultValue={values.minCost}
            error={errors.minCost && touched.minCost}
            fullWidth
            helperText={errors.minCost && touched.minCost && errors.minCost}
            label="Min Cost"
            name="minCost"
            onBlur={handleBlur}
            onChange={handleChange}
            type="number"
            variant="outlined"
          />
          <TextField
            defaultValue={values.maxCost}
            error={errors.maxCost && touched.maxCost}
            fullWidth
            helperText={errors.maxCost && touched.maxCost && errors.maxCost}
            label="Max Cost"
            name="maxCost"
            onBlur={handleBlur}
            onChange={handleChange}
            type="number"
            variant="outlined"
          />
        </div>
      </div>

      <div className="mb-6">
        <TextField
          defaultValue={values.planDetailsId}
          error={errors.planDetailsId && touched.planDetailsId}
          fullWidth
          helperText={
            errors.planDetailsId &&
            touched.planDetailsId &&
            errors.planDetailsId
          }
          label="Plan Summary Id"
          name="planDetailsId"
          onBlur={handleBlur}
          onChange={handleChange}
          variant="outlined"
        />
        <small className="form-text text-muted">
          You will find it in{' '}
          <Link component={RouterLink} to="/dashboard/plan-details">
            Plan Summary
          </Link>{' '}
          section.
        </small>
      </div>

      <div className="mb-6">
        <TextField
          defaultValue={values.claimFormUrl}
          error={errors.claimFormUrl && touched.claimFormUrl}
          fullWidth
          helperText={
            errors.claimFormUrl && touched.claimFormUrl && errors.claimFormUrl
          }
          label="Claim form URL"
          name="claimFormUrl"
          onBlur={handleBlur}
          onChange={handleChange}
          variant="outlined"
        />
      </div>

      <div className="mb-6">
        <TextField
          defaultValue={values.policyWordingUrl}
          error={errors.policyWordingUrl && touched.policyWordingUrl}
          fullWidth
          helperText={
            errors.policyWordingUrl &&
            touched.policyWordingUrl &&
            errors.policyWordingUrl
          }
          label="Policy wording URL"
          name="policyWordingUrl"
          onBlur={handleBlur}
          onChange={handleChange}
          variant="outlined"
        />
      </div>

      <div className="mb-6">
        <Editor
          editorClassName="draft-editor"
          editorState={editorState}
          onBlur={() =>
            setFieldValue(
              'summary',
              draftToHtml(convertToRaw(editorState.getCurrentContent()))
            )
          }
          onEditorStateChange={setEditorState}
          placeholder="The message goes here..."
          wrapperClassName="rich-editor plan-editor-wrapper"
        />
      </div>
    </>
  );
};

export default Form;
