import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Form as PlanForm } from './components';
import { Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { editPlanTravel } from 'features/plansTravel/plansTravelSlice';

const PlansSchema = Yup.object().shape({
  minCost: Yup.number()
    .moreThan(0)
    .required('maxCost is required. For visitors enter any random number.')
});

const PlanTravelUpdate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { planTravelId } = useParams();
  const planTravelStatus = useSelector((state) => state.plansTravel.status);
  const planTravel = useSelector(
    (state) => state.plansTravel.data.entities.plansTravel[planTravelId]
  );

  useEffect(() => {
    if (
      planTravelStatus === 'idle' ||
      (planTravelStatus === 'succeeded' && !planTravel)
    ) {
      navigate('/dashboard/plans-travel');
    }
  }, [planTravelStatus]);

  return (
    <>
      <div className="p-3">
        <Typography variant="h1">Update {planTravel?.name}</Typography>
      </div>
      <div className="p-3 max-w-md">
        {planTravel && (
          <Formik
            initialValues={{
              _id: planTravel._id,
              summary: planTravel.summary,
              minCost: planTravel.minCost,
              planDetailsId: planTravel.planDetailsId
            }}
            validationSchema={PlansSchema}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(editPlanTravel({ ...values, planTravelId }))
                .unwrap()
                .then(() => {
                  navigate('/dashboard/plans-travel');
                })
                .catch((err) => console.error(err))
                .finally(() => setSubmitting(false));
            }}
          >
            {({
              errors,
              touched,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              values
            }) => (
              <Form>
                <PlanForm
                  {...{
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    values
                  }}
                />

                <Button
                  color="primary"
                  disableElevation
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting ? 'Updating' : 'Update'}
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default PlanTravelUpdate;
