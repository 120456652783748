import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  Alert,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { parseISO, format } from 'date-fns';
import TextsmsIcon from '@mui/icons-material/Textsms';
import FilterListIcon from '@mui/icons-material/FilterList';
import { CSVLink } from 'react-csv';
import { useSearchParams } from 'react-router-dom';
import { PhoneFormat } from 'components';

const List = ({ result, quoteRequests }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [resultFiltered, setResultFiltered] = useState(result);

  useEffect(() => {
    setPage(parseInt(params.pageNumber) || 0);
  }, [params.pageNumber]);

  useEffect(() => {
    setResultFiltered(
      result
        .filter((item) => {
          if (searchParams.get('clientId')) {
            if (searchParams.get('clientId') === quoteRequests[item].clientId) {
              return item;
            }
          } else {
            return item;
          }
        })
        .filter((item) => {
          if (searchParams.get('search') && searchParams.get('search') !== '') {
            const search = searchParams.get('search').toLowerCase();
            return (
              (quoteRequests[item].name &&
                quoteRequests[item].name.toLowerCase().includes(search)) ||
              (quoteRequests[item].email &&
                quoteRequests[item].email.toLowerCase().includes(search)) ||
              (quoteRequests[item].phone &&
                quoteRequests[item].phone.includes(search))
            );
          } else {
            return item;
          }
        })
    );
  }, [result.length, searchParams.get('clientId'), searchParams.get('search')]);

  const handlePageChange = (event, page) => {
    setPage(page);
    navigate(
      `/dashboard/quote-requests/${page}?clientId=${
        searchParams.get('clientId') || ''
      }&search=${searchParams.get('search') || ''}`
    );
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    navigate(
      `/dashboard/quote-requests/0?clientId=${
        searchParams.get('clientId') || ''
      }&search=${searchParams.get('search') || ''}`
    );
  };

  return (
    <div className="p-3">
      {resultFiltered && resultFiltered.length ? (
        <>
          <div className="flex flex-col md:flex-row justify-start md:justify-between md:items-center mb-2">
            <div className="mb-2">
              <Typography color="textSecondary" gutterBottom variant="body2">
                {resultFiltered.length} records found. Page {page + 1} of{' '}
                {Math.ceil(resultFiltered.length / rowsPerPage)}
              </Typography>
            </div>

            <div>
              <CSVLink
                className="inline-block border border-blue-500 rounded py-2 px-3"
                data={resultFiltered.map((k) => {
                  return {
                    name: quoteRequests[k].name,
                    email: quoteRequests[k].email,
                    phone: quoteRequests[k].phone
                  };
                })}
                filename="quote-request-contacts.csv"
              >
                Download CSV
              </CSVLink>
            </div>
          </div>

          <PerfectScrollbar>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell>URL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((key) => (
                      <TableRow hover key={key}>
                        <TableCell>
                          {format(
                            parseISO(quoteRequests[key].createdAt),
                            'dd MMM, yyyy',
                            new Date()
                          )}
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center">
                            <div className="me-2">
                              <IconButton
                                aria-label="Send Text"
                                color="primary"
                                onClick={() => {
                                  navigate(
                                    `/dashboard/text/?to=${quoteRequests[key].phone}&clientId=${quoteRequests[key].clientId}`
                                  );
                                }}
                              >
                                <TextsmsIcon />
                              </IconButton>
                            </div>

                            <div>
                              {quoteRequests[key].name && (
                                <div className="my-2">
                                  {quoteRequests[key].name}
                                </div>
                              )}
                              {quoteRequests[key].email && (
                                <div className="my-2">
                                  {quoteRequests[key].email}
                                </div>
                              )}
                              {quoteRequests[key].phone && (
                                <div className="my-2">
                                  <PhoneFormat
                                    number={quoteRequests[key].phone}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center">
                            <div className="me-3">
                              <IconButton
                                aria-label="Filter Quotes"
                                color="secondary"
                                onClick={() => {
                                  setSearchParams({
                                    clientId: quoteRequests[key].clientId || '',
                                    search: searchParams.get('search') || ''
                                  });
                                }}
                              >
                                <FilterListIcon />
                              </IconButton>
                            </div>
                            <div className="w-96 truncate">
                              {quoteRequests[key].surl && (
                                <div className="mb-2">
                                  {quoteRequests[key].surl}
                                </div>
                              )}
                              <a href={quoteRequests[key].url} target="_blank">
                                {quoteRequests[key].url}
                              </a>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>

          <TablePagination
            component="div"
            count={resultFiltered.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
          />
        </>
      ) : (
        <Alert severity="warning">Your search returned 0 results.</Alert>
      )}
    </div>
  );
};

List.propTypes = {
  result: PropTypes.array.isRequired,
  quoteRequests: PropTypes.object.isRequired
};

export default List;
