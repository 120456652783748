import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  IconButton
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { toast } from 'react-toastify';
import { updatePlanTravelStatus } from 'features/plansTravel/plansTravelSlice';
import { useDispatch } from 'react-redux';

const List = ({ plans, result }) => {
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();

  return (
    <div>
      {result && result.length && (
        <>
          <Typography color="textSecondary" gutterBottom variant="body2">
            {result.length} records found.
          </Typography>

          <PerfectScrollbar>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>PlanID</TableCell>
                    <TableCell>Plan</TableCell>
                    <TableCell>Slug</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result.map((key) => (
                    <TableRow hover key={key}>
                      <TableCell>
                        <Tooltip title="click to copy">
                          <IconButton
                            aria-label="Copy PlanID"
                            color="primary"
                            size="small"
                            onClick={() => {
                              navigator.clipboard
                                .writeText(key)
                                .then(() => {
                                  toast.success('Plan ID copied to clipboard');
                                })
                                .catch((err) => {
                                  toast.error(err);
                                });
                            }}
                          >
                            <FileCopyIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {plans[key].name}
                        <div>
                          <Link
                            component={RouterLink}
                            to={`/dashboard/rates-travel/${key}/single`}
                          >
                            Single
                          </Link>
                          &nbsp;&nbsp;
                          <Link
                            component={RouterLink}
                            to={`/dashboard/rates-travel/${key}/multi`}
                          >
                            Multi
                          </Link>
                        </div>
                      </TableCell>
                      <TableCell>{plans[key].slug}</TableCell>
                      <TableCell>
                        {plans[key].isActive ? (
                          <span style={{ color: '#090' }}>Active</span>
                        ) : (
                          <span style={{ color: '#f00' }}>Inactive</span>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            dispatch(
                              updatePlanTravelStatus({
                                planId: key,
                                status: plans[key].isActive ? 0 : 1
                              })
                            );
                          }}
                          size="small"
                          variant="outlined"
                        >
                          {plans[key].isActive ? 'Deactivate' : 'Activate'}
                        </Button>{' '}
                        <Button
                          color="primary"
                          component={RouterLink}
                          size="small"
                          to={`${key}/update`}
                          variant="outlined"
                        >
                          Update
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </>
      )}
    </div>
  );
};

List.propTypes = {
  plans: PropTypes.object.isRequired
};

export default List;
