import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchPlans } from 'features/plans/plansSlice';

const usePlans = () => {
  const dispatch = useDispatch();
  const {
    status,
    error,
    data: {
      entities: { plans },
      result
    }
  } = useSelector((state) => state.plans);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchPlans())
        .unwrap()
        .then(() => {
          // nothing
        })
        .catch((error) => toast.error(error.message || 'failed to load plans'));
    }
  }, [status]);

  return { status, error, plans, result };
};

export default usePlans;
