import React from 'react';
import config from './navigationConfig';
import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';

const SideNav = ({ toggleSideNav }) => {
  /*const navOpen = useSelector((state) => state.general.navOpen);*/

  return (
    <ul className="sidenav-list p-2">
      {config.map((item) => {
        return (
          <li className="sidenav-item" key={item.title}>
            <Link
              to={item.href}
              className="flex flex-auto items-center w-full px-2 py-1"
              onClick={() => {
                if (window.innerWidth < 1024) {
                  toggleSideNav();
                }
              }}
            >
              {item.icon && <item.icon />}
              <span className="sidenav-item-title ml-3">{item.title}</span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default SideNav;
