import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import {
  getPlanMetaStudentMany,
  importPlanMetaStudent,
  deletePlanMetaStudent
} from 'api/planDetailStudentApi';
import { toast } from 'react-toastify';

const planMetaStudents = new schema.Entity(
  'planMetaStudents',
  {},
  { idAttribute: '_id' }
);
const planMetaStudentsSchema = [planMetaStudents];

export const fetchPlanMetaStudents = createAsyncThunk(
  'planMetaStudents/fetchPlanMetas',
  async (arg) => await getPlanMetaStudentMany(arg)
);

export const uploadMetaStudents = createAsyncThunk(
  'planMetaStudents/uploadMetaStudents',
  async (data) => await importPlanMetaStudent(data)
);

export const removePlanMetaStudents = createAsyncThunk(
  'planMetaStudents/removePlanMetaStudents',
  async () => await deletePlanMetaStudent()
);

export const plansSlice = createSlice({
  name: 'planMetaStudents',
  initialState: {
    data: { entities: { plans: {} }, result: [] },
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlanMetaStudents.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchPlanMetaStudents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        const normalizedPlans = normalize(
          action.payload,
          planMetaStudentsSchema
        );
        state.data = normalizedPlans;
        state.data.result = normalizedPlans.result || [];
        state.data.entities.planMetaStudents =
          normalizedPlans.entities.planMetaStudents || {};
      })
      .addCase(fetchPlanMetaStudents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(action.error.message || 'There was an error loading plans');
      })

      .addCase(uploadMetaStudents.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(uploadMetaStudents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        const normalizedPlans = normalize(
          action.payload,
          planMetaStudentsSchema
        );
        state.data = normalizedPlans;
        state.data.result = normalizedPlans.result || [];
        state.data.entities.planMetaStudents =
          normalizedPlans.entities.planMetaStudents || {};
      })
      .addCase(uploadMetaStudents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(
          action.error.message || 'There was an error importing affiliates'
        );
      })

      .addCase(removePlanMetaStudents.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(removePlanMetaStudents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.data.result = [];
        state.data.entities.planMetaStudents = {};
      })
      .addCase(removePlanMetaStudents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(
          action.error.message || 'There was an error deleting records'
        );
      });
  }
});

export default plansSlice.reducer;
