import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { PhoneFormat } from 'components';
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

const List = ({ clients, list }) => {
  let [searchParams] = useSearchParams();
  let [listFiltered, setListFiltered] = useState(list);

  useEffect(() => {
    if (searchParams.get('search')) {
      const searchTerm = searchParams.get('search').toLowerCase();

      setListFiltered(
        list.filter(
          (item) =>
            clients[item].name.includes(searchTerm) ||
            clients[item].email.toLowerCase().includes(searchTerm) ||
            clients[item].domain.toLowerCase().includes(searchTerm)
        )
      );
    } else {
      setListFiltered(list);
    }
  }, [searchParams.get('search')]);

  return (
    <div className="p-3">
      {listFiltered && listFiltered.length ? (
        <>
          <div className="p-3">
            <Typography color="textSecondary" gutterBottom variant="body2">
              {listFiltered.length} records found.
            </Typography>
          </div>
          <PerfectScrollbar>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Cell#</TableCell>
                    <TableCell>Domain</TableCell>
                    <TableCell align="center">Enquiry Log</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listFiltered.map((key) => (
                    <TableRow hover key={key}>
                      <TableCell>
                        <Link component={RouterLink} to={`${key}`}>
                          {clients[key].name}
                        </Link>
                      </TableCell>
                      <TableCell>{clients[key].email}</TableCell>
                      <TableCell>
                        <PhoneFormat number={clients[key].cellNo} />
                      </TableCell>
                      <TableCell>{clients[key].domain}</TableCell>
                      <TableCell align="center">
                        <Button
                          color="primary"
                          component={RouterLink}
                          size="small"
                          to={`/dashboard/quote-requests/?clientId=${key}`}
                          variant="outlined"
                        >
                          Quote Req.
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </>
      ) : (
        <div>Nothing found</div>
      )}
    </div>
  );
};

List.propTypes = {
  clients: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired
};

export default List;
