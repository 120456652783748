import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../components/Spinner/Spinner';
import { fetchShortUrls } from 'features/shortUrls/shortUrlsSlice';
import { Alert } from '@mui/material';
import { parseISO, format } from 'date-fns';
import clsx from 'clsx';

const ShortUrls = () => {
  const dispatch = useDispatch();
  const shortUrlsStatus = useSelector((state) => state.shortUrls.status);
  const result = useSelector((state) => state.shortUrls.data.result);
  const shortUrls = useSelector(
    (state) => state.shortUrls.data.entities.shortUrls
  );

  useEffect(() => {
    if (shortUrlsStatus === 'idle') {
      dispatch(fetchShortUrls());
    }
  }, [shortUrlsStatus]);

  return (
    <div className="p-3">
      <div className="text-4xl font-medium">Short URLs</div>
      {shortUrlsStatus === 'loading' && <Spinner />}

      {result && result.length ? (
        <>
          {result.map((id) => (
            <div key={id} className="py-4 border-b">
              <a
                className="block text-blue-600 font-bold"
                href={shortUrls[id]?.url}
                target="_blank"
              >
                {process.env.REACT_APP_SHORT_URL_DOMAIN}/
                {shortUrls[id]?.shortId}
              </a>
              <div className="text-sm truncate max-w-lg text-gray-400">
                {shortUrls[id]?.url}
              </div>
              <div className="mt-4 flex gap-2">
                <span
                  className={clsx(
                    shortUrls[id]?.count ? 'text-green-600' : 'text-gray-500',
                    'text-sm font-bold'
                  )}
                >
                  {shortUrls[id]?.count} engagement
                </span>
                <span className="text-sm text-gray-400">
                  {format(
                    parseISO(shortUrls[id]?.updatedAt),
                    'dd MMM, yyyy H:mm aaa',
                    new Date()
                  )}
                </span>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="p-3">
          <Alert severity="warning">0 short urls</Alert>
        </div>
      )}
    </div>
  );
};

export default ShortUrls;
