import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Button,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';

const Header = () => {
  const { handleSubmit, control } = useForm();
  const navigate = useNavigate();

  const onSubmit = ({ search }) => {
    navigate(`/dashboard/clients?search=${search}`);
  };

  return (
    <div className="p-3">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-4">
        <div className="my-3">
          <Typography variant="h1">Clients</Typography>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="search"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <OutlinedInput
                placeholder="Search clients"
                value={value}
                onChange={onChange}
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            )}
          />
        </form>
      </div>

      <div className="flex sm:justify-end">
        <Button
          color="primary"
          component={RouterLink}
          to="add"
          variant="contained"
        >
          Add Client
        </Button>
      </div>
    </div>
  );
};

export default Header;
