import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Form as PlanForm } from './components';
import { Button, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addClient, editClient } from 'features/clients/clientsSlice';
import { useParams, useNavigate } from 'react-router-dom';

const ClientUpdateSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Enter a valid email address.')
    .required('Email is required'),
  cellNo: Yup.string()
    .matches(/^\d{10}$/, { message: 'enter a valid 10 digit only cell#' })
    .required('Cell# is required'),
  domain: Yup.string()
    .matches(
      /^(?!www\.)(?!.*\..*\..*)(localhost|([a-zA-Z0-9]+\.[a-zA-Z]{2,}))$/,
      {
        message: 'enter a valid domain without www/subdomain name'
      }
    )
    .required('Domain is required'),
  options: Yup.object().shape({
    coverage: Yup.number().min(1000),
    deductible: Yup.number().min(0).max(10000),
    plans: Yup.array()
  }),
  twilio: Yup.object()
    .shape({
      accountId: Yup.string(),
      token: Yup.string(),
      phone: Yup.string().matches(/^\d{10}$/, {
        message: 'enter a valid 10 digit only phone#'
      })
    })
    .test({
      name: 'either-or',
      test: function (value) {
        const { accountId, token, phone } = value;
        if ((!accountId && !token && !phone) || (accountId && token && phone)) {
          return true;
        }
        return this.createError({
          message:
            'Either none or all (Account ID, Token, and Twilio Phone#) must be present for Twilio',
          path: 'twilio.accountId' // You can adjust this to any relevant path
        });
      }
    })
});

const ClientNewSchema = ClientUpdateSchema.shape({
  password: Yup.string().min(6).required('Password is required')
});

const ClientAddUpdate = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const clients = useSelector((state) => state.clients.data.entities.clients);
  const navigate = useNavigate();

  return (
    <div className="p-3 md:w-96">
      <Typography variant="h1">
        {clients?.[clientId]
          ? `Update ${clients[clientId].name}`
          : 'Add Client'}
      </Typography>
      <Formik
        enableReinitialize
        initialValues={
          clientId
            ? {
                name: clients?.[clientId]?.name,
                email: clients?.[clientId]?.email,
                cellNo: clients?.[clientId]?.cellNo,
                domain: clients?.[clientId]?.domain,
                quoteMsg: clients?.[clientId]?.quoteMsg,
                twilio: {
                  accountId: clients?.[clientId]?.twilio?.accountId,
                  token: clients?.[clientId]?.twilio?.token,
                  phone: clients?.[clientId]?.twilio?.phone
                },
                options: {
                  coverageSuper:
                    clients?.[clientId]?.options?.coverageSuper || 100000,
                  coverageVisitor:
                    clients?.[clientId]?.options?.coverageVisitor || 50000,
                  deductible: clients?.[clientId]?.options?.deductible || 0,
                  plans: clients?.[clientId]?.options?.plans || []
                }
              }
            : {
                name: clients?.[clientId]?.name,
                email: clients?.[clientId]?.email,
                cellNo: clients?.[clientId]?.cellNo,
                domain: clients?.[clientId]?.domain,
                password: '',
                quoteMsg: clients?.[clientId]?.quoteMsg,
                twilio: {
                  accountId: clients?.[clientId]?.twilio?.accountId,
                  token: clients?.[clientId]?.twilio?.token,
                  phone: clients?.[clientId]?.twilio?.phone
                },
                options: {
                  coverageSuper: 100000,
                  coverageVisitor: 50000,
                  deductible: 0,
                  plans: []
                }
              }
        }
        validationSchema={clientId ? ClientUpdateSchema : ClientNewSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(
            clients?.[clientId]
              ? editClient({ ...values, clientId })
              : addClient(values)
          )
            .unwrap()
            .then(() => {
              toast.success('Client added/updated');
              navigate(`/dashboard/clients/${clientId || ''}`);
            })
            .catch((err) => {
              console.log('In catch error', err);
              toast.error(
                err.data?.message || err.message || 'An error occurred'
              );
            });
          setSubmitting(false);
        }}
      >
        {({
          errors,
          touched,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          values
        }) => (
          <Form>
            <PlanForm
              {...{
                errors,
                touched,
                handleBlur,
                handleChange,
                setFieldValue,
                values,
                clientId: clientId || null
              }}
            />

            <Button
              color="primary"
              disableElevation
              disabled={isSubmitting}
              type="submit"
              variant="contained"
            >
              {isSubmitting ? 'Submitting' : 'Submit'}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ClientAddUpdate;
