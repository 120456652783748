import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { TopBar, SideNav } from './components';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

const navbarWidth = 280;

const SideNavWrapper = styled.div(({ isNavOpen }) => ({
  width: `${navbarWidth}px`,
  color: '#fff',
  left: isNavOpen ? '0' : `-${navbarWidth}px`
}));

const MainContentWrapper = styled.div(({ isNavOpen }) => ({
  marginLeft: isNavOpen ? `${navbarWidth}px` : '0'
}));

const TopBarWrapper = styled.div(({ isNavOpen }) => ({
  marginLeft: isNavOpen ? `${navbarWidth}px` : '0'
}));

const Dashboard = () => {
  const navigate = useNavigate();
  const [navOpen, setNavOpen] = useState(true);

  const toggleSideNav = () => {
    setNavOpen(!navOpen);
  };

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setNavOpen(false);
    }

    const user = JSON.parse(localStorage.getItem('user'));

    if (!user) {
      navigate('/login');
    }
  }, []);

  return (
    <div className="flex w-full overflow-hidden">
      <SideNavWrapper
        className="sidebar fixed top-0 bottom-0 z-50 h-full min-h-screen transition-all duration-300 bg-blue-800"
        isNavOpen={navOpen}
      >
        <SideNav toggleSideNav={toggleSideNav} />
      </SideNavWrapper>
      <MainContentWrapper
        className="main-content w-full transition-all duration-300"
        isNavOpen={navOpen}
      >
        <TopBarWrapper
          className="p-2 bg-blue-50 fixed left-0 right-0 transition-all duration-300 z-50"
          isNavOpen={navOpen}
        >
          <TopBar className="w-full" onOpenNavBarMobile={toggleSideNav} />
        </TopBarWrapper>

        <div className="mt-14">
          <Outlet />
        </div>
      </MainContentWrapper>
    </div>
  );
};

export default Dashboard;
