import React from 'react';

const Affiliates = ({ affiliates, plans }) => {
  return (
    <>
      {affiliates && affiliates.length ? (
        <>
          {affiliates.map((item) => (
            <div
              key={item._id}
              className="flex w-full py-1 border-b border-gray-200"
            >
              <>
                <div className="w-1/5">
                  {plans && plans[item.planId]
                    ? plans[item.planId].name
                    : item.planId}
                </div>
                <div className="w-4/5">
                  <ul className="list-disc ms-5">
                    <li>{item.url}</li>
                    <li>{item.urlPreExisting}</li>
                  </ul>
                </div>
              </>
            </div>
          ))}
        </>
      ) : (
        <>import affiliates</>
      )}
    </>
  );
};

export default Affiliates;
