import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

const Header = () => {
  return (
    <div className="p-3 flex md:justify-between md:items-center">
      <Typography variant="h1">Plans Travel</Typography>

      <Button
        color="primary"
        component={RouterLink}
        to="add"
        variant="contained"
      >
        Add Plan Travel
      </Button>
    </div>
  );
};

export default Header;
