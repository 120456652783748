import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import { getQuoteRequests } from '../../api/quoteRequestsApi';
import { toast } from 'react-toastify';

const quoteRequests = new schema.Entity(
  'quoteRequests',
  {},
  { idAttribute: '_id' }
);
const quoteRequestsSchema = [quoteRequests];

export const fetchQuoteRequests = createAsyncThunk(
  'quoteRequests/fetchQuoteRequests',
  async (params) => await getQuoteRequests(params)
);

export const quoteRequestsSlice = createSlice({
  name: 'quoteRequests',
  initialState: {
    data: { entities: { quoteRequests: {} }, result: [] },
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchQuoteRequests.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchQuoteRequests.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        const normalizedQuoteRequests = normalize(
          action.payload,
          quoteRequestsSchema
        );
        state.data = normalizedQuoteRequests;
        state.data.result = normalizedQuoteRequests.result || [];
        state.data.entities.quoteRequests =
          normalizedQuoteRequests.entities.quoteRequests || {};
      })
      .addCase(fetchQuoteRequests.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(
          action.error.message || 'There was an error getting quote Requests'
        );
      });
  }
});

export default quoteRequestsSlice.reducer;
