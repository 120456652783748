import axios from './axios';
import { PlanTypesSlugMap } from 'utilities/config';

const baseUrl = '/rates';

export function getRates({ planId, planType }) {
  return axios({
    method: 'get',
    url: `${baseUrl}/${PlanTypesSlugMap[planType]}/${planId}`
  });
}

export function createRate(data) {
  return axios({
    method: 'post',
    url: baseUrl,
    data
  });
}

export function importRates({ csv, planId, planTypeSlug }) {
  let data = new FormData();
  data.append('csv', csv);
  data.append('planId', planId);

  return axios({
    method: 'post',
    url: `${baseUrl}/${planTypeSlug}/import`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  });
}

export function deleteAllRates(plan) {
  console.log('in api', plan);
  return axios({
    method: 'delete',
    url: `${baseUrl}/${PlanTypesSlugMap[plan.planType]}/${plan._id}`
  });
}

/*export function updateRate(data) {
  return axios({
    method: 'put',
    url: `${baseUrl}/${data._id}`,
    data
  });
}

export function deleteRate(data) {
  return axios({
    method: 'delete',
    url: `${baseUrl}/${data._id}`
  });
}*/
