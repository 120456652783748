import React from 'react';
import { Button, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="text-center">
      <div className="mb-6">
        <Typography variant="h1">Oops! page not found.</Typography>
      </div>
      <p className="mb-6">
        Sorry the page you are looking for does not exists.
      </p>

      <div className="flex justify-center">
        <div>
          <Button
            color="primary"
            disableElevation
            onClick={goBack}
            variant="contained"
          >
            Back
          </Button>
        </div>
        <div className="ms-3">
          <Button
            color="primary"
            component={RouterLink}
            disableElevation
            to="/dashboard/clients"
            variant="outlined"
          >
            Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
