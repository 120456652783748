import axios from './axios';

const baseUrl = '/plan-summary';

export function getPlanDetails() {
  return axios({
    method: 'get',
    url: baseUrl
  });
}

// get single plans complete details
export function getPlanDetail(planDetailId) {
  return axios({
    method: 'get',
    url: `${baseUrl}/${planDetailId}`
  });
}

export function savePlanDetail(data) {
  console.log('in save', data);
  return axios({
    method: 'post',
    url: baseUrl,
    data
  });
}

export function updatePlanDetail({ planDetailId, ...rest }) {
  return axios({
    method: 'put',
    url: `${baseUrl}/${planDetailId}`,
    data: { ...rest }
  });
}

export function updatePlanDetailField({ planDetailId, field, ...rest }) {
  return axios({
    method: 'put',
    url: `${baseUrl}/${planDetailId}/${field}`,
    data: { ...rest, _id: planDetailId }
  });
}

export function deletePlanDetail(planDetailId) {
  return axios({
    method: 'delete',
    url: `${baseUrl}/${planDetailId}`
  });
}

/*
export function updatePlanDetailField(planDetail) {
  console.log(planDetail);
  return fetch(`${baseUrl}/${planDetail._id}/${planDetail.field}`, {
    method: 'PUT',
    headers: { 'content-type': 'application/json', ...authHeader() },
    body: JSON.stringify(planDetail)
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deletePlanDetail(planDetailId) {
  return fetch(baseUrl + '/' + planDetailId, {
    method: 'DELETE',
    headers: { ...authHeader() }
  })
    .then(handleResponse)
    .catch(handleError);
}
*/
