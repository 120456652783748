import React, { useEffect, useState } from 'react';
import {
  TextField,
  Autocomplete,
  OutlinedInput,
  InputAdornment
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClients } from 'features/clients/clientsSlice';
import { useSearchParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';

const Search = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const clientStatus = useSelector((state) => state.clients.status);
  const result = useSelector((state) => state.clients.data.result);
  const clients = useSelector((state) => state.clients.data.entities.clients);
  const { handleSubmit, control } = useForm();

  useEffect(() => {
    if (clientStatus === 'idle') {
      dispatch(fetchClients());
    }
  }, [clientStatus]);

  const onSubmit = ({ search }) => {
    //navigate(`/dashboard/clients?search=${search}`);

    setSearchParams({
      clientId: searchParams.get('clientId') || '',
      search: search || ''
    });
  };

  return (
    <div className="md:flex">
      <div className="my-2 me-2">
        <Autocomplete
          id="client-search"
          autoHighlight
          value={searchParams.get('clientId') || null}
          onChange={(event, newValue) => {
            setSearchParams({
              clientId: newValue || '',
              search: searchParams.get('search') || ''
            });
          }}
          sx={{ width: 300 }}
          options={result}
          getOptionLabel={(idx) =>
            clients?.[idx]?.name + '-' + clients?.[idx]?.domain
          }
          renderInput={(params) => (
            <TextField {...params} label="Search IIC Clients" />
          )}
        />
      </div>

      <div className="my-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="search"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <OutlinedInput
                sx={{ width: 300 }}
                placeholder="Search contacts"
                value={value || searchParams.get('search') || ''}
                onChange={(e) => {
                  onChange(e);
                  if (e.target.value === '') {
                    onSubmit({ search: null });
                  }
                }}
                type="search"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            )}
          />
        </form>
      </div>
    </div>
  );
};

export default Search;
