const toUpperCaseEachWord = (str) => {
  const words = str.split(' ');
  for (let i = 0; i < words.length; i++) {
    if (words[i][0]) {
      words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }
  }

  return words.join(' ');
};

export default toUpperCaseEachWord;
