export const PlanTypes = {
  0: 'Visitor and Super',
  1: 'Visitor',
  2: 'Super',
  3: 'Student'
};

export const PlanTypesSlugMap = {
  0: 'visitor',
  1: 'visitor',
  2: 'visitor',
  3: 'student'
};

export const Status = {
  true: 'Active',
  false: 'De-Active'
};

export const CloudHostingUrl =
  'https://storage.googleapis.com/visitorinsurancezone';
