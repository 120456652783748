import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { removePlanMetaVisitors } from 'features/planMetaVisitor/planMetaVisitorSlice';
import { removePlanMetaStudents } from 'features/planMetaStudent/planMetaStudentSlice';

const Header = ({ type }) => {
  const dispatch = useDispatch();

  return (
    <div className="p-3 flex flex-col md:flex-row md:justify-between md:items-center">
      <Typography variant="h1">
        Plans Details - {type === 'visitor' ? 'Visitors' : 'Int. Students'}
      </Typography>
      <div className="flex">
        <div className="me-3">
          <Button
            color="primary"
            component={RouterLink}
            to="import"
            variant="contained"
          >
            Import
          </Button>
        </div>
        <Button
          color="secondary"
          onClick={() => {
            dispatch(
              type === 'visitor'
                ? removePlanMetaVisitors()
                : removePlanMetaStudents()
            );
          }}
          variant="contained"
        >
          Delete All
        </Button>
      </div>
    </div>
  );
};

export default Header;
